import { makeAutoObservable } from 'mobx';
import { Store } from './store';
import { TableData } from '../components/ReactPdfTable/HTMLReactPdfTable';

export default class HtmlFragmentsStore implements Store {
    htmlFragments: Record<string, string> = {};
    htmlTablesData: Record<string, TableData[]> = {};

    constructor() {
        makeAutoObservable(this);
    }
    updateHtmlFragments(key: string, value: string) {
        this.htmlFragments[key] = value;
    }
    updateHtmlTablesData(key: string, value: TableData[]) {
        this.htmlTablesData[key] = value;
    }
    clearHtmlFragments() {
        this.htmlFragments = {};
    }
    clearHtmlTablesData() {
        this.htmlTablesData = {};
    }
    getHtmlFragments() {
        return this.htmlFragments;
    }
}