import React, { useEffect, useRef, useState } from 'react';
import {
    PdfLoader,
    PdfHighlighter,
    Highlight,
    Popup,
    AreaHighlight,
    IHighlight,
} from '../../react-pdf-highlighter';
// import './Sample.css';
import CircularProgress from '@mui/joy/CircularProgress';
import './pdf-style.css';
import axios from 'axios';
import { BACKEND_URL, FILE_DOWNLOAD_PATH } from '../../constants/paths';
import { DEFAULT_COFIG } from '../../utils/configs';
import { getHighlightById, parseHighlightIdFromHash } from '../../utils/annotation-utils';
import { observer } from 'mobx-react';

const HighlightPopup = ({
    comment,
}: {
    comment: { text: string; emoji: string };
}) =>
    comment.text ? (
        <div className="Highlight__popup">
            {comment.emoji} {comment.text}
        </div>
    ) : null;

export interface IPdfHighlightViewerProps {
    highlights: IHighlight[];
    sourceFilePath: string;
    taskId: string;
}
const PdfHighlightViewer: React.FC<IPdfHighlightViewerProps> = (props) => {
    const scrollViewerToRef = useRef((highlight: IHighlight) => {
    });
    const [url, setUrl] = useState<string>('')
    useEffect(() => {
        const config = {
            ...DEFAULT_COFIG,
            params: {
                task_id: props.taskId,
                file_path: props.sourceFilePath,
            }
        }
        console.log(`PdfHighlightViewer fetching file for taskId: ${props.taskId}, sourceFilePath: ${props.sourceFilePath}`)
        axios.get(`${BACKEND_URL}/${FILE_DOWNLOAD_PATH}`, config).then((response) => {
            console.log(response)
            setUrl(response.data.url)
        }).catch((error) => {
            console.log(error)
            setUrl('')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sourceFilePath])

    const scrollToHighlightFromHash = () => {
        const parsedId = parseHighlightIdFromHash();
        const highlight = getHighlightById({ highlights: props.highlights, id: parsedId });

        if (highlight) {
            scrollViewerToRef.current(highlight as IHighlight);
        }
    };

    useEffect(() => {
        window.addEventListener(
            "hashchange",
            scrollToHighlightFromHash,
            false
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div key="shuying_dev" >
            <PdfLoader url={url} beforeLoad={<CircularProgress color="neutral" />}>
                {(pdfDocument, eventBus) => (
                    <PdfHighlighter
                        pdfDocument={pdfDocument}
                        eventBus={eventBus}
                        enableAreaSelection={(event) => event.altKey}
                        onScrollChange={() => {
                            console.log("scroll changed");
                        }}
                        // pdfScaleValue="page-width"
                        scrollRef={(scrollTo) => {
                            scrollViewerToRef.current = scrollTo;
                            scrollToHighlightFromHash();
                        }}
                        onSelectionFinished={(
                            position,
                            content,
                            hideTipAndSelection,
                            transformSelection
                        ) => (null)}
                        highlightTransform={(
                            highlight,
                            index,
                            setTip,
                            hideTip,
                            viewportToScaled,
                            screenshot,
                            isScrolledTo
                        ) => {
                            const isTextHighlight = !Boolean(
                                highlight.content && highlight.content.image
                            );

                            const component = isTextHighlight ? (
                                <Highlight
                                    isScrolledTo={isScrolledTo}
                                    position={highlight.position}
                                // comment={highlight.comment}
                                />
                            ) : (
                                <AreaHighlight
                                    isScrolledTo={isScrolledTo}
                                    highlight={highlight}
                                    onChange={(boundingRect) => {
                                    }}
                                />
                            );
                            return component;
                        }}
                        highlights={props.highlights}
                    />
                )}
            </PdfLoader>
        </div>
    );
}
export default observer(PdfHighlightViewer);