import { createContext, useContext, useEffect } from "react";
import { userStore } from "../../stores/store";
import AuthContext from "./AuthContext";
import React from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress } from '@mui/joy';
import Box from '@mui/material/Box';

const UserContext = createContext(userStore);
export const UserContextProvider = observer(({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const [loading, setLoading] = React.useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        if (isAuthenticated) {
            console.log(`Initialize user context...`)
            userStore.getUser().then((res) => {
                console.log(`UserContextProvider: res.data = ${JSON.stringify(res.data)}`)
                setLoading(false);
            }).catch((err) => {
                console.log(`UserContextProvider: err = ${JSON.stringify(err)}`)
                setLoading(false);
            });
        } else {
            userStore.resetUser();
            setLoading(false);
        }
    }, [isAuthenticated]);
    return (
        loading ? (
            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src="/img/hona_logo_with_text.png" alt="Logo" style={{marginLeft: "20px", height: "50px", marginBottom: "20px", marginTop: "20px"}} />
        <CircularProgress color="neutral" />
            </Box>
        )
            : (
                <UserContext.Provider value={userStore}>
                    {children}
                </UserContext.Provider>
            )
    );
});

export default UserContext;


