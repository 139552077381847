import { makeAutoObservable } from 'mobx';
import { Store } from './store';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { BACKEND_URL, CREATE_SUMMARY_TASK_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';
import { IAnnotationHighlight } from '../types/annotation-highlight';
import { IDataSource } from '../types/data-source';

export interface ICreateTask {
    status: 'Pending' | 'Started' | 'Error';
    patientIdentifier: string;
    errorMessage: string;
    taskId?: string;
}

export default class FileStore implements Store {
    highlights: IAnnotationHighlight[] = [];
    dataSources: IDataSource[] = [];
    annotations: Record<string /* summary id*/, any> = {};

    constructor() {
        makeAutoObservable(this);
    }
    resetDataSources(dataSources: IDataSource[]) {
        this.dataSources = dataSources;
    }
}