import { makeAutoObservable, runInAction } from 'mobx';
import { Store, highlightStore, } from './store';
import axios from 'axios';
import { BACKEND_URL, RISK_FACTORS_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';
import { IRiskFactor } from '../types/risk-factor';
import { SummaryType } from '../components/SummaryViewPage/constants';
import { IAnnotationHighlight } from '../types/annotation-highlight';

export default class RiskFactorStore implements Store {
    riskFactors: IRiskFactor[] = [];
    riskFactorId: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }
    async fetchRiskFactors(riskFactorId: string) {
        const result = await axios.get(
            `${BACKEND_URL}/${RISK_FACTORS_PATH}${riskFactorId}/`, DEFAULT_COFIG
        );
        runInAction(() => {
            this.riskFactors = result.data.diagnoses;
            this.riskFactorId = result.data.id;
            const highlights = this.getHightlightsFromAnnotation();
            highlightStore.updateHighlights(highlights, SummaryType.risk_factor);
        });
        return result;
    }
    private getHightlightsFromAnnotation(): IAnnotationHighlight[] {
        return this.riskFactors.filter((riskfactor) => riskfactor.annotation !== null && riskfactor.annotation !== undefined).map((riskfactor) => {
            return riskfactor.annotation as IAnnotationHighlight;
        });
    }
}