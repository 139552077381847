import { makeAutoObservable, runInAction } from 'mobx';
import { Store } from './store';
import axios from 'axios';
import { BACKEND_URL, SUMMARY_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';
import { getDebugMode } from '../utils/get-stage';
import { IAnnotationHighlight } from '../types/annotation-highlight';
import { ILine, ISection } from '../types/summary';
import { IDataSource, ISourceTabParams } from '../types/data-source';
import { FILES_SUBTAB_TITLE, OVERVIEW_SUBTAB_TITLE, SummaryType } from '../components/SummaryViewPage/constants';
import { IHighlight } from '../react-pdf-highlighter';

export interface ISummary {
    taskId: string;
    summaryId: string;
    summaryData: any;
}
export default class HighlightStore implements Store {
    highlights: Record<SummaryType, IAnnotationHighlight[]> = {
        summary: [],
        riskfactor: [],
        timeline: [],
    }
    constructor() {
        makeAutoObservable(this);
    }

    getPdfHighlights(summaryType: SummaryType): IHighlight[] {
        if (!summaryType) {
            return [];
        }
        return this.highlights[summaryType].filter((highlight) => (typeof highlight.position !== 'string')) as IHighlight[];
    }

    getFormHighlights(summaryType: SummaryType): IAnnotationHighlight[] {
        if (!summaryType) {
            return [];
        }
        return this.highlights[summaryType].filter((highlight) => (typeof highlight.position === 'string')) as IAnnotationHighlight[];
    }

    updateHighlights(highlights: IAnnotationHighlight[], summaryType: SummaryType) {
        if (!summaryType) {
            return;
        }
        this.highlights[summaryType] = highlights;
    }
}