import React from "react";
import Main from "./Main";
import { AuthContextProvider } from "./components/Context/AuthContext";
import { UserContextProvider } from "./components/Context/UserContext";


const App: React.FC = () => {
  return (
    <AuthContextProvider>
      <UserContextProvider>
        <Main />
      </UserContextProvider>
    </AuthContextProvider>
  );
};

export default App;
