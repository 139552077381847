import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import React, { useContext, lazy, Suspense } from 'react';
import { ACTIVATE_ACCOUNT_ROUTER_LINK, ALL_SUMMARIES_LINK, DASHBOARD_ROUTER_LINK, LANDING_PAGE_ROUTER_LINK, LOGIN_ROUTER_LINK, PROFILE_ROUTER_LINK, RESET_PASSWORD_CONFIRMATION_ROUTER_LINK, RESET_PASSWORD_ROUTER_LINK, SETTINGS_LINK, SIGN_IN_ROUTER_LINK, TEMPLATES_ROUTE_LINK, VERIFY_ACCOUNT_ROUTER_LINK, NEW_SUMMARY_ROUTER_LINK, SUMMARY_DETAILS_LINK, DOCUMENT_VIEWER_ROUTE_LINK, SCHEDULE_ROUTE_LINK, PDF_HIGHLIGHTER_LINK, EMAIL_VERIFIED_ROUTE_LINK } from './constants/router-links';
import AuthContext from './components/Context/AuthContext';
import SettingsPage from './components/pages/SettingsPage';
import TemplatesPage from './components/pages/TemplatesPage';
import { Theme, useTheme } from '@mui/material';
import { drawerWidthOpen } from './components/Common/MiniSideBar';
import Notification from './components/Common/Notification';
import { CircularProgress } from '@mui/joy';
import './fonts.css';
import { createTheme, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@material-ui/core/styles';
import DocumentViewPage from './components/pages/DocumentViewPage';
import MiniSideBar from './components/Common/MiniSideBar';
import Box from '@mui/material/Box';
import { SidebarHeader } from './components/Common/MiniSideBar';
import { getStage } from './utils/get-stage';

// const LandingPage = lazy(() => import('./components/pages/LandingPage'));
const SignInPage = lazy(() => import('./components/pages/SignInPage'));
const DashboardPage = lazy(() => import('./components/pages/DashboardPage'));
const ProfilePage = lazy(() => import('./components/pages/ProfilePage'));
const SummaryCreatePage = lazy(() => import('./components/pages/SummaryCreatePage'));
const AllSummariesPage = lazy(() => import('./components/pages/AllSummariesPage'));
const ActivateAccountPage = lazy(() => import('./components/pages/ActivateAccountPage'));
const TermsOfServicePage = lazy(() => import("./components/pages/TermsOfServicePage"));
const ResetPasswordPage = lazy(() => import('./components/pages/ResetPasswordPage'));
const ResetPasswordConfirmPage = lazy(() => import('./components/pages/ResetPasswordConfirmPage'));
const SummaryViewPage = lazy(() => import('./components/pages/SummaryViewPage'));
const SchedulePage = lazy(() => import('./components/pages/SchedulePage'));
const PdfHighlighterPage = lazy(() => import('./components/pages/PdfHighlighterPage'));
const VerifyAccountSuccessPage = lazy(() => import('./components/pages/VerifyAccountSuccessPage'));
interface IMainProps {
}

const globalStyles = (
  <GlobalStyles styles={{
    'body, .MuiTypography-root': {
      fontFamily: 'HelveticaNowProTextRegular, Arial, sans-serif',
    },
    // Any other global styles you want to add
  }} />
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#EDEDED',
    },

  },
  typography: {
    fontFamily: [
      'HelveticaNowProTextRegular', // Replace with your font name
      'Arial',        // Fallback font
      'sans-serif'    // Generic font family
    ].join(','),
  },
});

const WithNavigationBars = () => {
  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <MiniSideBar />
        <Box component="main" sx={{ flexGrow: 1, padding: '1rem',
          overflow: 'hidden',
        }}>
          <SidebarHeader />
          <Outlet />
        </Box>
      </Box>
    </div>
  )
};

const ProtectedRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  // TODO: Add "didAcceptTOS" to the context

  return (
    <>
      {isAuthenticated === false ? (
        <Navigate replace to={SIGN_IN_ROUTER_LINK} />
      ) : isAuthenticated === null ? (
        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <img src="/img/hona_logo_with_text.png" alt="Logo" style={{ marginLeft: "20px", height: "50px", marginBottom: "20px", marginTop: "20px" }} />
          <CircularProgress color="neutral" />
        </Box>
      ) : (
        // TODO: if didAcceptTOS is false, then redirect to TOS page
        // else render the outlet
        <Outlet />
      )}
    </>
  );
};

const Main: React.FC<IMainProps> = () => {
  const stage = getStage();
  return (
    <>
      <ThemeProvider theme={theme}>
        {globalStyles}
        <Notification />
        <BrowserRouter>
          <Suspense fallback={<Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <img src="/img/hona_logo_with_text.png" alt="Logo" style={{ marginLeft: "20px", height: "50px", marginBottom: "20px", marginTop: "20px" }} />
            <CircularProgress color="neutral" />
          </Box>}>
            <Routes>
              <Route path={EMAIL_VERIFIED_ROUTE_LINK} element={<VerifyAccountSuccessPage />} />
              <Route path={LANDING_PAGE_ROUTER_LINK} element={<Navigate replace to={NEW_SUMMARY_ROUTER_LINK} />} />
              <Route path={SIGN_IN_ROUTER_LINK} element={<SignInPage />} />
              <Route path={LOGIN_ROUTER_LINK} element={<TermsOfServicePage />} />
              <Route path={RESET_PASSWORD_ROUTER_LINK} element={<ResetPasswordPage />} />
              <Route path={RESET_PASSWORD_CONFIRMATION_ROUTER_LINK} element={<ResetPasswordConfirmPage />} />
              <Route path={ACTIVATE_ACCOUNT_ROUTER_LINK} element={<ActivateAccountPage />} />
              <Route path={VERIFY_ACCOUNT_ROUTER_LINK} element={<Navigate replace to={NEW_SUMMARY_ROUTER_LINK} />} />
              <Route element={<ProtectedRoutes />}>
                <Route element={<WithNavigationBars />}>
                  <Route path={DASHBOARD_ROUTER_LINK} element={<DashboardPage />} />
                  {stage !== 'prod' && <Route path={PDF_HIGHLIGHTER_LINK} element={<PdfHighlighterPage />} />}
                  <Route path={PROFILE_ROUTER_LINK} element={<ProfilePage />} />
                  <Route path={NEW_SUMMARY_ROUTER_LINK} element={<SummaryCreatePage />} />
                  <Route path={ALL_SUMMARIES_LINK} element={<AllSummariesPage />} />
                  <Route path={`${SUMMARY_DETAILS_LINK}/:taskId`} element={<SummaryViewPage />} />
                  <Route path={TEMPLATES_ROUTE_LINK} element={<TemplatesPage />} />
                  <Route path={SETTINGS_LINK} element={<SettingsPage />} />
                  <Route path={`${DOCUMENT_VIEWER_ROUTE_LINK}`} element={<DocumentViewPage />} />
                  <Route path={SCHEDULE_ROUTE_LINK} element={<SchedulePage />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter >
      </ThemeProvider>
    </>
  );

}

export default Main;
