import { getStage } from "../utils/get-stage";

export const LOGIN_VERIFY_PATH = "api/v1/auth/login/verify/";
export const GET_USER_INFO_PATH = "api/v1/users/me/";
export const USER_PATH = "api/v1/users/user/";
export const GET_ORGANIZATION_AUTH_CONFIGS_PATH = "api/v1/auth/auth-configs/organizations/";
export const GET_AUTH_CONFIGS_PATH = "api/v1/auth/get-auth-configs/";
export const GET_UPLOAD_URL_PATH = "api/v1/files/get-upload-url/";
export const LOGOUT_PATH = "api/v1/auth/logout/";

export const TEMPLATES_PATH = "api/v1/resources/templates/";
export const GET_DOCUMENT_PATH = "api/v1/resources/document/";
export const CREATE_SUMMARY_TASK_PATH = "api/v1/summary/tasks/create/";
export const TASKS_PATH = "api/v1/summary/tasks/";
export const CREATE_TASK_SHARE_PATH = "api/v1/summary/task-share/create/";
export const USERS_PATH = "api/v1/summary/users/";
export const GROUPS_PATH = "api/v1/summary/groups/";
export const SUMMARY_PATH = "api/v1/summary/summaries/";
export const RISK_FACTORS_PATH = "api/v1/summary/risk-factors/";
export const TIMELINE_PATH = "api/v1/summary/timeline/";
export const UPLOAD_START_PATH = "api/v1/files/upload/direct/start/";
export const UPLOAD_MULTIPART_PATH = "api/v1/files/upload/direct/multipart-presigned-url/";
export const UPLOAD_FINISH_PATH = "api/v1/files/upload/direct/finish/";
export const TEMPLATES_GALLERY_PATH = "api/v1/resources/templates/gallery/";
export const PARTICLE_UI_SCHEMA_PATH = "api/v1/resources/templates/particleUiSchema/";
export const GET_PATIENT_PATH = "api/v1/fhir/patient";
export const FILE_DOWNLOAD_PATH = "api/v1/files/download/";
export const GET_FILE_PATH = "api/v1/files/";
export const GET_CLIENT_CONFIG_PATH = "api/v1/fhir/client-config";

const stage = getStage();

export const BACKEND_URL_BY_STAGE: Record<string, string> = {
    local: "http://localhost:8000",
    dev: "https://api.dev.hona.ai",
    prod: "https://api.hona.ai",
};
export const BACKEND_URL = BACKEND_URL_BY_STAGE[stage];
