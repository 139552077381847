import { makeAutoObservable, runInAction } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import { BACKEND_URL, GET_USER_INFO_PATH, USER_PATH } from '../constants/paths';
import { Store } from './store';
import { DEFAULT_USER, IUser } from '../types/user';
import { DEFAULT_COFIG } from '../utils/configs';

export default class UserStore implements Store {
    user: IUser = DEFAULT_USER;

    constructor() {
        makeAutoObservable(this);
    }

    async getUser(): Promise<AxiosResponse<any>> {
        console.log('fetching user info...')
        const result = await axios.get<IUser>(
            `${BACKEND_URL}/${GET_USER_INFO_PATH}`,
            DEFAULT_COFIG,
        );
        runInAction(() => {
            this.user = result.data;
        });
        return result;
    }

    async updateUser(userId: string, data: any): Promise<AxiosResponse<any>> {
        console.log('updating user info...')
        const result = await axios.put(
            `${BACKEND_URL}/${USER_PATH}${userId}/update/`,
            data,
            DEFAULT_COFIG,
        );
        runInAction(() => {
            this.user = result.data;
        });
        return result;
    }

    resetUser() {
        this.user = DEFAULT_USER;
    }
}
