import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import { notificationStore } from '../../stores/store';

export interface IProps {
}

const Notification: React.FC<IProps> = () => {
    const handleClose = () => {
        notificationStore.closeNotification();
    };
    return (
        <Snackbar
            open={notificationStore.open}
            onClose={handleClose}
            autoHideDuration={6000}
            style={{ zIndex: 10000000000 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                onClose={handleClose}
                severity={notificationStore.severity}
                sx={{ width: '500px' }}
                variant="filled"
                elevation={6}
            >
                {notificationStore.message}
            </Alert>
        </Snackbar>
    );
};

export default observer(Notification);