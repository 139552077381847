import { useEffect, useState } from "react";
import { PDFFindBar } from "./pdf_find_bar";
import { Button, IconButton, Input, Stack, Typography } from "@mui/joy";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';



interface PDFFindBarProps {
    eventBus: any;
}
const PDFFindBarComponent: React.FC<PDFFindBarProps> = (props) => {
    const [pdfFindBar, setPdfFindBar] = useState<PDFFindBar>();
    useEffect(() => {
        const { eventBus } = props;

        // this.bar = options.bar;
        // this.toggleButton = options.toggleButton;
        // this.findField = options.findField;
        // this.highlightAll = options.highlightAllCheckbox;
        // this.caseSensitive = options.caseSensitiveCheckbox;
        // this.matchDiacritics = options.matchDiacriticsCheckbox;
        // this.entireWord = options.entireWordCheckbox;
        // this.findMsg = options.findMsg;
        // this.findResultsCount = options.findResultsCount;
        // this.findPreviousButton = options.findPreviousButton;
        // this.findNextButton = options.findNextButton;
        // this.eventBus = eventBus;

        const options = {
            bar: document.getElementById('findbar'),
            toggleButton: document.createElement("button"),
            // toggleButton: document.getElementById('viewFind'),
            highlightAllCheckbox: document.createElement("div"),
            caseSensitiveCheckbox: document.createElement("div"),
            entireWordCheckbox: document.createElement("div"),
            findMsg: document.createElement("div"),
            findResultsCount: document.getElementById("findResultsCount"),
            findField: document.getElementById('findInput'),
            findPreviousButton: document.getElementById('findPrevious'),
            findNextButton: document.getElementById('findNext'),
            matchDiacriticsCheckbox: document.createElement("input"),

        }
        const pdfFindBar = new PDFFindBar(options, eventBus);

        setPdfFindBar(pdfFindBar);

        return () => {
            if (pdfFindBar) {
                pdfFindBar.nerf();
            }
        }
    }, [props.eventBus]);

    return (
        <div id="findbar" style={{ marginBottom: '1rem', marginLeft: "1rem" }}>
            <Stack direction="row" spacing={2}>
                {/* <input type="text" placeholder="Enter search keyword" id="findInput" /> */}
                {/* <button id="viewFind">Toggle Button</button> */}
                <Input id="findInput" placeholder="Enter search keyword" startDecorator={<SearchIcon />} />                

                <IconButton id="findPrevious">
                    <ArrowUpwardIcon/>
                </IconButton>
                <IconButton id="findNext">
                    <ArrowDownwardIcon />
                </IconButton>
            </Stack>
        </div>
    )
}
export default PDFFindBarComponent;
