import { makeAutoObservable } from "mobx";
import axios from "axios";
import { BACKEND_URL } from "../constants/paths";
import { DEFAULT_COFIG } from "../utils/configs";
import { Encounter, Practitioner } from "../components/SchedulePage/types";
import EncounterTableColumnConfig from "../data-schemas/EncounterTableColumnConfig.json";
import { Store } from './store';

export default class EncountersStore implements Store {
  practitionerData : Practitioner[] = [];
  encounterData : Encounter[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchAllMockData() {
    try {
        const resultPractitionerData = await fetchData("api/v1/fhir/practitioner", { identifier: "test_group"});
        let practitioners = [];
        for (let i: number = 0; i < resultPractitionerData.entry.length; i++) {
            let temp: Practitioner = {
                id: resultPractitionerData.entry[i].resource.id,
                firstName: resultPractitionerData.entry[i].resource.name[0].given[0],
                lastName: resultPractitionerData.entry[i].resource.name[0].family,
            };
            practitioners.push(temp);
        }
        this.practitionerData = practitioners;

        const encounterData = await fetchData("api/v1/fhir/encounter", { identifier: "all_encounters"});
        let dataRows = [];
        for (let i = 0; i < encounterData.entry.length; i++) {
            const practitionerId = encounterData.entry[i].resource.participant[0].individual.reference.split("/")[1];

            let temp: Encounter = {
                id: i,
                date: "2022-10-01",
                encounter_status: encounterData.entry[i].resource.status,
                patient_name: encounterData.entry[i].resource.subject.display,
                patient_id: encounterData.entry[i].resource.subject.reference,
                practitioner_name:
                    encounterData.entry[i].resource.participant[0].individual
                        .display +
                    " (id:" +
                    practitionerId +
                    ")",
                practitioner_id: practitionerId,
                summary_schedule: "Not Started",
            };
            dataRows.push(temp);
        }

        const encounterDataRows = getDataForTableColumns(
            dataRows,
            EncounterTableColumnConfig
        );
        this.encounterData = encounterDataRows;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      console.log("Data fetched successfully");
    }
  }
}

async function fetchData(path: string,
    params: Record<string, any> = {}) {
    try {
        const response = await axios.get(`${BACKEND_URL}/${path}`, {
            params: params,
            ...DEFAULT_COFIG,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const getDataForTableColumns = (
    data: Record<string, any>[],
    columns: any[]
) => {
    return data.map((row) => {
        const rowData: any = {};
        columns.forEach((column) => {
            rowData[column.field] = row[column.field] ?? "";
        });
        rowData["id"] = row["id"];
        return rowData;
    });
};