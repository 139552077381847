import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useState, useContext } from "react";
import AuthContext from "../Context/AuthContext";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { logout } from "../../actions/auth";
import { useNavigate } from "react-router-dom";
import {
    DASHBOARD_ROUTER_LINK,
    PROFILE_ROUTER_LINK,
    NEW_SUMMARY_ROUTER_LINK
} from "../../constants/router-links";
import UserContext from "../Context/UserContext";
import { settings, drawerWidthOpen, drawerWidthClose } from "./MiniSideBar";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { SidebarHeader } from './MiniSideBar';
import { getCookieByName } from "../../utils/cookie-utils";

const headerBarHeight = "80px";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidthOpen,
        width: `calc(100% - ${drawerWidthOpen}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        width: `calc(100% - ${drawerWidthClose}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }),
}));

export const HeaderBar = ({ open, handleSidebarOpen }: {
    open: boolean,
    handleSidebarOpen: () => void
}) => {
    const theme = useTheme();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const { user } = useContext(UserContext);
    const disableOrganizationDisplay = getCookieByName("ehr_name") === "google" || getCookieByName("client_name").startsWith("AUTH0");
    const organizationName = user.organization?.name ?? getCookieByName("client_name");
    const { updateIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (event: any, setting: any) => {
        setAnchorElUser(null);
        if (setting === "Logout") {
            logout()
                .then(() => {
                    updateIsAuthenticated(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (setting === "Dashboard") {
            navigate(DASHBOARD_ROUTER_LINK);
        } else if (setting === "Profile") {
            navigate(PROFILE_ROUTER_LINK);
        }
    };

    return (
        <AppBar
            position="fixed"
            open={open}
            sx={{ ...themedStyles(theme).appBar }}
            style={{ borderBottom: "1px solid #EBEBEB", zIndex: 10 }}
            elevation={0}
        >
            <Toolbar
                disableGutters
                style={{
                    width: "100%",
                    display: "flex",
                }}
            >
                {!disableOrganizationDisplay && organizationName && <Box sx={{ flexGrow: 1, marginLeft: '1rem', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar src="/img/medical-icons/organization.png" />
                    <Typography
                        variant="h5"
                        sx={{ marginLeft: '0.5rem', justifyContent: "center", color: "black", fontFamily: "HelveticaNowProTextRegular" }}
                    >{organizationName}</Typography>
                </Box>}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Tooltip title="Open settings">
                        <IconButton
                            onClick={handleOpenUserMenu}
                            sx={{ p: 0, marginRight: "20px" }}
                        >
                            <Avatar
                                alt={user?.name && user?.name !== ""
                                    ? user?.name
                                    : "?"}
                                src="/static/images/avatar/dummy.jpg" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem
                                key={setting}
                                onClick={(event) => handleCloseUserMenu(event, setting)}
                            >
                                <Typography textAlign="center">
                                    {setting}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

const themedStyles = (theme: Theme) => {
    return {
        menuButton: {
            marginRight: 2,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: "#FFFFFF",
            borderWidth: "1px",
            height: headerBarHeight,
            alighItems: "center",
            display: "flex",
            justifyContent: "center",
        },
        drawer: {
            width: drawerWidthOpen,
            "& .MuiBackdrop-root": {
                display: "none",
            },
        },
        drawerPaper: {
            width: drawerWidthOpen,
            backgroundColor: "rgba(120,120,120,0.2)",
        },
        content: {
            padding: 3,
            maxWidth: 720,
            minWidth: 375,
            marginLeft: drawerWidthOpen + 8,
        },
    };
};