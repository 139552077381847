import Button from '@mui/joy/Button';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Card, Modal, ModalClose } from '@mui/joy';
import { notificationStore, templateStore } from '../../stores/store';

interface TemplateEditModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    templateId: string;
    tableRevision?: number;
    setTableRevision?: (tableRevision: number) => void;
    isPublished: boolean | undefined;
}
const TemplatePublishModal = (props: TemplateEditModalProps) => {
    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <ModalDialog layout={'center'}
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ minWidth: 1000 }}
            >
                <ModalClose />
                <Stack direction="column" spacing={2}>
                    {props.isPublished === true ? <Typography level="title-lg">Are you sure you want to publish the following template?</Typography> : <Typography level="title-lg">Are you sure you want to unpublish the following template?</Typography>}
                    <Card sx={{
                        padding: 2, backgroundColor: '#f5f5f5', maxWidth: '50rem', marginBottom: 2
                    }}>
                        < Typography level="body-md" key={props.templateId} > <b>template id</b>: {props.templateId}</Typography>
                        <Typography level="body-md" key={props.templateId}

                            sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}><b>template name</b>: {templateStore.getTemplateById(props.templateId)?.name ?? ''}</Typography>
                    </Card>
                    <Stack direction="row" spacing={2}>
                        <Button variant="solid" style={{ backgroundColor: '#000000', fontWeight: 'bold' }} onClick={() => props.setOpen(false)}>Cancel</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#000000', fontWeight: 'bold' }} onClick={() => {
                            templateStore.updateTemplate(props.templateId, { is_published: props.isPublished }).then(() => {
                                props.setOpen(false);
                                props.setTableRevision && props.setTableRevision(props.tableRevision ? props.tableRevision + 1 : 1);
                            }).catch((error) => {
                                props.setOpen(false);
                                notificationStore.createNotification('error', 'Failed to update template');
                                props.setTableRevision && props.setTableRevision(props.tableRevision ? props.tableRevision + 1 : 1);
                            });
                        }}>Confirm</Button>
                    </Stack>
                </Stack>
            </ModalDialog>
        </Modal>

    )
};

export default TemplatePublishModal;