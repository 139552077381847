import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, Switch, Typography } from "@mui/joy";
import { ChangeEvent } from "react";

// const EditAndDeleteActions = (param: GridCellParams) => {
const EditAndDeleteActions = (props: any) => {
    const handleEdit = () => {
        props.setOpenEdit(true);
    }
    const handleDelete = () => {
        props.setOpenDelete(true);
    }
    return (
        <div>
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={handleEdit}
            />
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDelete}
            />
        </div>
    );
};

const DefaultCell = (props: any) => {
    return (
        <div>
            {props.value as string}
        </div>
    );
}
const IsPublishedCell = (props: any) => {
    const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
        props.setOpenPublishModal(true);
        props.setIsPublished(e.target.checked);
    }
    return (
        <Stack direction="row" spacing={2}>
            <Typography level="body-md">
                {props.value === true ? "Yes" : "No"}
            </Typography>
            <Switch variant="solid" onChange={(e) => handleToggle(e)} checked={props.value} />
        </Stack>
    );
}
const CellComponents: Record<string, (props: any) => JSX.Element> = {
    'EditAndDeleteActions': EditAndDeleteActions,
    'IsPublishedCell': IsPublishedCell,
};

export const getCellComponent = (componentName: string) => {
    return CellComponents[componentName] ?? DefaultCell;
}
