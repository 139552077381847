import axios from "axios";
import { BACKEND_URL } from "../constants/paths";
import { DEFAULT_COFIG } from "./configs";
import { notificationStore } from "../stores/store";
import { ISourceDocument } from "../types/data-source";
import { convertToDate, displayDateUTC } from "./date-time";
import { isNullOrEmpty } from "./object-utils";

// Get the source path to display in the summary view. Sometimes the source file is an OCR file, sometimes it's the original file.
export const getSourcePath = (source: ISourceDocument) => {
    if (source.ocr_file_path) {
        return source.ocr_file_path;
    }
    return source.file_path;
}

export const getFileNameFromMetadata = async (file: ISourceDocument): Promise<string | null> => {
    const file_topic = file.metadata?.file_topic;
    if (!isNullOrEmpty(file_topic)) {
        return file_topic;
    }
    const file_id = file.metadata?.file_id;
    if (file_id !== undefined) {
        axios.get(`${BACKEND_URL}/api/v1/files/${file_id}`, DEFAULT_COFIG).then((res) => {
            return res.data.original_file_name ?? null;
        }).catch((err) => {
            notificationStore.createNotification('error', `Failed to get file ${file_id}`)
            return null;
        });
    } else {
        try {
            return file.metadata?.type?.text ?? (file.metadata.category ? `Diagnostic Report - ${file.metadata.category[0].text}` : null);
        } catch (err) {
            // In case category is not an array.
            return null;
        }
    }
    return null;
}

export const getCreationDateFromMetadata = (file: ISourceDocument): string | undefined => {
    try {
        const date = convertToDate(file.metadata?.date || file.metadata?.issued);
        let displayDate = undefined;
        if (date.toString() !== 'Invalid Date') {
            displayDate = displayDateUTC(date.valueOf());
        }
        return displayDate;
    } catch (err) {
        return undefined;
    }
}