import { makeAutoObservable, runInAction } from 'mobx';
import { Store } from './store';
import axios from 'axios';
import { BACKEND_URL, TIMELINE_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';

export interface ITimelineEntry {
    date: string;
    title: string;
    event: string;
    diagnosis: string;
    source: string;
}

export default class TimelineStore implements Store {
    timelineEvents: ITimelineEntry[] = [];
    timelineId: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }
    async fetchTimeline(timelineID: string) {
        console.log(`fetchTimeline: ${timelineID}`);
        const result = await axios.get(
            `${BACKEND_URL}/${TIMELINE_PATH}${timelineID}/`, DEFAULT_COFIG
        );
        console.log(`fetchTimeline: ${JSON.stringify(result.data)}`);

        runInAction(() => {
            this.timelineEvents = result.data.timeline_entries;
            this.timelineId = timelineID;
        });

        return result;
    }
}