import { SOURCE_NAME_PARAM, SOURCE_PANEL_PARAM, SUMMARY_TYPE_PARAM, SummaryType } from "../components/SummaryViewPage/constants";
import { summaryStore } from "../stores/store";
import { IAnnotationHighlight } from "../types/annotation-highlight";
import { IAnnotationPosition } from "./parse-summary";

const DOMAIN = window.location.origin;

export const parseHighlightIdFromHash = () => {
    const hash = document.location.hash;
    if (!hash) {
        return '';
    }
    if (hash.startsWith("#highlight-")) {
        return hash.slice("#highlight-".length);
    }
    // this is from pdf export
    if (hash.startsWith("#annotation_id=")) {
        return hash.slice("#annotation_id=".length);
    }
    return '';
}

export const parseSummaryTypeFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    if (!params.has(SUMMARY_TYPE_PARAM)) {
        // set default to summary because that's the default tab being open
        return SummaryType.summary;
    }
    return params.get('summaryType') as SummaryType;
}
export const parseIdFromHash = () =>
    document.location.hash.slice("#highlight-".length);

export const getHighlightById = (props: { highlights: any[], id: string }) => {
    const result = props.highlights.find((highlight) => highlight.id === props.id);
    return result;
}

export const parseAnnotationPosition = (position?: string): IAnnotationPosition | null => {
    if (!position) {
        return null;
    }
    try {
        const positionSplit = position.split(':');
        const tableName = positionSplit[0];
        const id = parseInt(positionSplit[1]);
        return { tableName, rowIndex: id };

    } catch (error) {
        console.log(`Error parsing annotation position: ${position}`);
        return null;
    }
}
// This is used to find the element in FormHighlighViwer to scroll to when a user clicks on a highlighted sentence in the summary.
export const getAnnotationElementId = ({ tableName, index }: { tableName: string, index: string }) => {
    return `${tableName}-details-${index}`
}

export const createAnnotationUrl = ({
    documentId,
    taskId,
    summaryId,
    annotationId }
    : { documentId: string, taskId: string, summaryId: string, annotationId?: string }) => {
    const baseUrl = `${DOMAIN}/document?id=${documentId}&task_id=${taskId}&summary_id=${summaryId}`;
    if (annotationId) {
        return `${baseUrl}#annotation_id=${annotationId}`;
    }
    return baseUrl;
}

// This is used in export PDF to populate "src" tag in <Link> or the "href" tag in <a> for a annotated sentence in the summary.
export const createAnnotationAnchor = ({ annotation, taskId, summaryId }:
    { annotation: IAnnotationHighlight, taskId: string, summaryId: string }) => {

    // Internal link
    if (typeof (annotation.position) === 'string') {
        const pos = parseAnnotationPosition(annotation.position);
        const anchor = `overview-${annotation.source.document_id}-${pos?.tableName}-details-${pos?.rowIndex}`;
        return anchor;
    }

    // External link
    return createAnnotationUrl({ documentId: annotation.source.document_id, taskId, summaryId, annotationId: annotation.id });
}

export const updateHash = (highlight: IAnnotationHighlight, summaryType: SummaryType) => {
    console.log(`updating highlight. Highlight id: ${highlight.id}. highlight: ${JSON.stringify(highlight.position)}, document_id: ${highlight.source.document_id}`);
    document.location.hash = `highlight-${highlight.id}`;
    const source = summaryStore.getSourceTabParamsFromDocumentId(highlight.source.document_id);
    if (source) {
        const params = new URLSearchParams(window.location.search);
        params.set(SOURCE_NAME_PARAM, source.sourceName);
        params.set(SOURCE_PANEL_PARAM, source.sourcePanel)
        params.set(SUMMARY_TYPE_PARAM, summaryType);
        window.history.replaceState({}, '', `?${params.toString()}${window.location.hash}`);
    }
};