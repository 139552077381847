import { Card, Stack, Switch, Typography } from "@mui/joy";
import Divider from '@mui/material/Divider';
import { ChangeEvent, useContext, useState } from "react";
import UserContext from "../Context/UserContext";
import { isHonaDomain } from "../../constants/allowlists";
import { getStage } from "../../utils/get-stage";
import '../../fonts.css';

const SettingsPage = () => {
    const { user } = useContext(UserContext);
    const stage = getStage();
    const debugModeEnabled = stage !== 'prod' && isHonaDomain(user.email as string);
    if (!localStorage.getItem('debug_mode')) {
        localStorage.setItem('debug_mode', 'false');
    }
    const debug_mode = localStorage.getItem('debug_mode') === 'true' ? true : false;
    const [debugMode, setDebugMode] = useState(debug_mode);
    const handleDebugMode = (event: ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem('debug_mode', event.target.checked ? 'true' : 'false');
        setDebugMode(event.target.checked);
        console.log(`debug mode changed to: ${event.target.checked}`);
    }

    return (
        debugModeEnabled ?
            <div>
                <Card variant='outlined' sx={{ padding: 2, minWidth: '400px', maxWidth: '1000px', display: 'flex' }}>
                    <Stack direction="column">
                        <Typography level="h3">Settings</Typography>
                        <div style={{ height: '2rem' }}>
                            <Divider light sx={{ margin: 2 }} />
                        </div>
                        <Stack direction="row" marginBottom={4}>
                            <Typography level="title-md" sx={{ fontWeight: 'bold', width: '200px' }} > Debug Mode: </Typography>
                            <Switch
                                checked={debugMode}
                                onChange={(event) => handleDebugMode(event)}
                            />
                        </Stack>
                    </Stack>
                </Card >
            </div> :
            <div>
                <h1>No Settings Available</h1>
            </div>
    )
}

export default SettingsPage;