import { makeAutoObservable } from 'mobx';
import { Store } from './store';
import { AlertColor } from '@mui/material';

export default class NotificationStore implements Store {
    severity: AlertColor = 'info';
    message: string = '';
    open: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    closeNotification() {
        this.setOpen(false);
    }

    createNotification(severity: AlertColor, message: string) {
        this.setSeverity(severity);
        this.setMessage(message);
        this.setOpen(true);
    }

    private setSeverity(severity: AlertColor) {
        this.severity = severity;
    }

    private setMessage(message: string) {
        this.message = message;
    }

    private setOpen(open: boolean) {
        this.open = open;
    }

}