export const getStage = () => {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
        return 'local';
    } else if (hostname === 'console.dev.hona.ai') {
        return 'dev';
    } else if (hostname === 'console.hona.ai') {
        return 'prod';
    }
    return 'test'; // personal stack
}

export const getDebugMode = () => {
    const stage = getStage();
    return stage !== 'prod' && localStorage.getItem('debug_mode') === 'true' ? true : false;
}