const optionsUTC: any = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC',
};
const optionsLocal: any = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
};
const optionsForDateTime: any = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
};
const optionsForMonthDay: any = {
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC',
};
const optionsForMonthYear: any = {
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
};
const optionsForWeekdayDateTime: any = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
};

const ENGLISH_DATE_FORMAT_UTC = new Intl.DateTimeFormat('en-US', optionsUTC);
const ENGLISH_DATE_FORMAT_LOCAL = new Intl.DateTimeFormat('en-US', optionsLocal);
const ENGLISH_DATE_MONTH_DAY_FORMAT = new Intl.DateTimeFormat('en-US', optionsForMonthDay);
const ENGLISH_DATE_MONTH_YEAR_FORMAT = new Intl.DateTimeFormat('en-US', optionsForMonthYear);
const ENGLISH_DATETIME_FORMAT = new Intl.DateTimeFormat('en-US', optionsForDateTime);

export const displayDateUTC = (dateInMillis: number): string => {
    return ENGLISH_DATE_FORMAT_UTC.format(dateInMillis);
};
export const displayDateLocal = (dateInMillis: number): string => {
    return ENGLISH_DATE_FORMAT_LOCAL.format(dateInMillis);
};
export const displayMonthDayUTC = (dateInMillis: number): string => {
    return ENGLISH_DATE_MONTH_DAY_FORMAT.format(dateInMillis);
};
export const displayMonthYearUTC = (dateInMillis: number): string => {
    return ENGLISH_DATE_MONTH_YEAR_FORMAT.format(dateInMillis);
};
export const displayDateTimeLocal = (dateInMillis: number): string => {
    return ENGLISH_DATETIME_FORMAT.format(dateInMillis);
};


// convert datatime string like 2023-07-03T22:38:04.262885Z to Date object
// 2013-01-01T00:00:00+00:00
export const convertToDate = (dateTime: string) => {
    const date = new Date(dateTime);
    return date;

}

// Convert mm/dd/yyyy to yyyy-mm-dd
export const convertDateFormat = (inputDate: string) => {
    var dateParts = inputDate.split('/');
    if (dateParts.length !== 3) {
        return inputDate;
    }
    var month = dateParts[0];
    var day = dateParts[1];
    var year = dateParts[2];
    // Create the new date in "yyyy-mm-dd" format
    var newDateFormat = year + '-' + month + '-' + day;
    return newDateFormat;
}

export const displayDate = (dateTime: string) => {
    const date = convertToDate(dateTime);

    let displayDate = '';
    if (date.toString() === 'Invalid Date') {
        displayDate = dateTime;
    } else {
        displayDate = displayDateUTC(date.valueOf());
    }
    return displayDate;
}