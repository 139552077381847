import ModalDialog from '@mui/joy/ModalDialog';
import { Modal, ModalClose } from '@mui/joy';
import TemplateEditCard from './TemplateEditCard';

interface TemplateEditModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    templateId?: string;
    tableRevision: number;
    setTableRevision: (tableRevision: number) => void;
}
const TemplateEditModal = (props: TemplateEditModalProps) => {
    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <ModalDialog layout={'center'}
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ minWidth: 1000 }}
            >
                <ModalClose />
                <TemplateEditCard
                    templateId={props.templateId}
                    setOpen={props.setOpen}
                    tableRevision={props.tableRevision}
                    setTableRevision={props.setTableRevision} />
            </ModalDialog>
        </Modal>

    )
};

export default TemplateEditModal;