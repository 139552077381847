import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    PROFILE_ROUTER_LINK,
    NEW_SUMMARY_ROUTER_LINK,
} from "../../constants/router-links";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FilterNoneOutlinedIcon from "@mui/icons-material/FilterNoneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
    ALL_SUMMARIES_LINK,
    SETTINGS_LINK,
    TEMPLATES_ROUTE_LINK,
    SCHEDULE_ROUTE_LINK,
} from "../../constants/router-links";
import "../../fonts.css";
import {
    ThemeProvider,
    GlobalStyles,
} from "@mui/material";
import { HeaderBar } from "./HeaderBar";
import { SidebarDrawer } from "./SidebarDrawer";
import Button from "@mui/joy/Button";

export const drawerWidthOpen = 240;
export const drawerWidthClose = 60;
export const settings = ["Profile", "Logout"];

export const SidebarHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    height: "80px",
    ...theme.mixins.toolbar,
}));

export default function MiniSideBar() {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = useState("");

    useEffect(() => {
        setSelected(location.pathname);
    }, [location.pathname]);

    const handleSidebarOpen = () => {
        setOpen(true);
    };

    const handleSidebarClose = () => {
        setOpen(false);
    };

    const handleOnClickSummarySection1 = () => {
        navigate(NEW_SUMMARY_ROUTER_LINK);
    };

    const handleOnClickSummarySection2 = () => {
        navigate(ALL_SUMMARIES_LINK);
    };
    const handleOnClickTemplates = () => {
        navigate(TEMPLATES_ROUTE_LINK);
    };

    const handleOnClickProfile = () => {
        navigate(PROFILE_ROUTER_LINK);
    };

    const handleOnClickSettings = () => {
        navigate(SETTINGS_LINK);
    };

    const handleOnClickSchedule = () => {
        navigate(SCHEDULE_ROUTE_LINK);
    };

    const ListItemButtonStyle = {
        minHeight: 60,
        justifyContent: open ? "initial" : "center",
        px: 2.5,
        paddingY: 1.5,
    };
    const ListItemIconStyle = {
        minWidth: 0,
        mr: open ? 3 : "auto",
        justifyContent: "center",
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                {globalStyles}
                <CssBaseline />
                <HeaderBar open={open} handleSidebarOpen={handleSidebarOpen} />
                <SidebarDrawer variant="permanent" open={open}>
                    <SidebarHeader sx={SideBarHeaderStyle}>
                        {open && (
                            <Box sx={OpenedLogoButtonStyle}>
                                <IconButton
                                    onClick={() => {
                                        navigate(ALL_SUMMARIES_LINK);
                                    }}
                                    sx={{
                                        height: "100%",
                                        margin: 0,
                                        padding: 0,
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                        },
                                        borderRadius: 0,
                                    }}
                                >
                                    <img
                                        src="/img/hona_logo_with_text.png"
                                        alt="Logo"
                                        style={{
                                            height: "35px",
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                            marginLeft: "18px",
                                        }}
                                    />
                                </IconButton>

                                <IconButton onClick={handleSidebarClose}
                                    sx={{marginRight: "2px"}}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Box>
                        )}

                        {!open && (
                            <IconButton
                                onClick={handleSidebarOpen}
                                sx={ClosedLogoButtonStyle}
                            >
                                <img
                                    src="/img/hona_logo_only.png"
                                    alt="Logo"
                                    style={{
                                        position: "absolute",
                                        height: "35px",
                                        left: "18px",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}
                                />
                                <Box sx={FloatingArrowIconStyle}>
                                    <ChevronRightIcon
                                        sx={{width: "15px", height: "15px"}}
                                    />
                                </Box>
                            </IconButton>
                        )}
                    </SidebarHeader>
                    <List sx={{ margin: 0, padding: 0 }}>

                        <ListItem disablePadding
                        >
                            <ListItemButton
                                sx={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "row",
                                    borderRadius: 1,
                                    marginX: "8px",
                                    marginY: "8px",
                                    // backgroundColor: "#F1F0F5",
                                    backgroundColor: "black",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    "&:hover": {
                                        backgroundColor: "#000000",
                                    },
                                }}
                                // className={
                                //     selected === NEW_SUMMARY_ROUTER_LINK
                                //         ? classes.selectedItem
                                //         : ""
                                // }
                                onClick={handleOnClickSummarySection1}
                            >
                                <ListItemIcon sx={{
                                    justifyContent: "center",
                                }}>
                                    <AddOutlinedIcon
                                        style={{ 
                                            color: "white",
                                            fontSize: "28px"
                                        }}
                                        fontSize="inherit"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ opacity: open ? 1 : 0}}
                                    primaryTypographyProps={{ 
                                        style: {
                                            fontWeight:"bold",
                                            color: "white",
                                } }}
                                    primary="New Summary"
                                    className={classes.listItemText}
                                />

                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                sx={ListItemButtonStyle}
                                onClick={handleOnClickSummarySection2}
                                className={
                                    selected === ALL_SUMMARIES_LINK
                                        ? classes.selectedItem
                                        : ""
                                }
                            >
                                <ListItemIcon sx={ListItemIconStyle}>
                                    <MenuOutlinedIcon
                                        style={{ color: "black" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primary="All Summaries"
                                    className={classes.listItemText}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                sx={ListItemButtonStyle}
                                onClick={handleOnClickTemplates}
                                className={
                                    selected === TEMPLATES_ROUTE_LINK
                                        ? classes.selectedItem
                                        : ""
                                }
                            >
                                <ListItemIcon
                                    sx={ListItemIconStyle}
                                    style={{ color: "#000000" }}
                                >
                                    <FilterNoneOutlinedIcon
                                        style={{ color: "black" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primary="Templates"
                                    className={classes.listItemText}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                sx={ListItemButtonStyle}
                                onClick={handleOnClickSchedule}
                                className={
                                    selected === SCHEDULE_ROUTE_LINK
                                        ? classes.selectedItem
                                        : ""
                                }
                            >
                                <ListItemIcon
                                    sx={ListItemIconStyle}
                                    style={{ color: "#000000" }}
                                >
                                    <CalendarTodayIcon />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primary="Schedule"
                                    className={classes.listItemText}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                sx={ListItemButtonStyle}
                                onClick={handleOnClickProfile}
                                className={
                                    selected === PROFILE_ROUTER_LINK
                                        ? classes.selectedItem
                                        : ""
                                }
                            >
                                <ListItemIcon
                                    sx={ListItemIconStyle}
                                    style={{ color: "#000000" }}
                                >
                                    <AccountBoxOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primary="Profile"
                                    className={classes.listItemText}
                                />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                sx={ListItemButtonStyle}
                                onClick={handleOnClickSettings}
                                className={
                                    selected === SETTINGS_LINK
                                        ? classes.selectedItem
                                        : ""
                                }
                            >
                                <ListItemIcon
                                    sx={ListItemIconStyle}
                                    style={{ color: "#000000" }}
                                >
                                    <SettingsOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primary="Settings"
                                    className={classes.listItemText}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </SidebarDrawer>
            </ThemeProvider>
        </>
    );
}

const globalStyles = (
    <GlobalStyles
        styles={{
            "*": {
                fontFamily:
                    "HelveticaNowProTextRegular, Arial, sans-serif !important",
            },
        }}
    />
);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        width: drawerWidthOpen,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidthOpen,
    },
    content: {
        flexGrow: 1,
    },
    selectedItem: {
        backgroundColor: "#EDEDED !important", 
        fontFamily: "HelveticaNowProTextRegular !important",
        color: "white", 
        "&:hover": {
            backgroundColor: "#000000", 
            fontFamily: "HelveticaNowProTextRegular !important",
        },
    },
    listItemText: {
        color: "black", 
        fontFamily: "HelveticaNowProTextRegular !important",
    },
    listItemIcon: {
        filter: "invert(0%) brightness(0%)",
    },
}));

const SideBarHeaderStyle = {
    borderBottom: "none",
    height: "80px",
    margin: 0,
    padding: 0,
};

const OpenedLogoButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
};
const ClosedLogoButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    "&:hover": {
        backgroundColor: "transparent",
    },
    borderRadius: 0,
};
const FloatingArrowIconStyle = {
    position: "absolute",
    right: 5,
    bottom: 5,
    backgroundColor: "#EDF0F9",
    borderRadius: "100%",
    height: "15px",
    width: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};