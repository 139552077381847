import { makeAutoObservable, runInAction } from 'mobx';
import { Store, highlightStore, } from './store';
import axios from 'axios';
import { BACKEND_URL, SUMMARY_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';
import { getDebugMode } from '../utils/get-stage';
import { IAnnotationHighlight } from '../types/annotation-highlight';
import { ILine, IQuote, ISection, ISummarySection } from '../types/summary';
import { IDataSource, ISourceTabParams } from '../types/data-source';
import { FILES_SUBTAB_TITLE, OVERVIEW_SUBTAB_TITLE, SummaryType } from '../components/SummaryViewPage/constants';
import { IHighlight } from '../react-pdf-highlighter';
import { json } from 'stream/consumers';

export interface ISummary {
    taskId: string;
    summaryId: string;
    summaryData: any;
}
export default class SummaryStore implements Store {
    summaries: Record<string /* task id*/, ISummary> = {};
    annotations: any = {}; // deprecated. Annotations are no longer used.
    annotatedSummary: any = {};

    dataSources: Record<string /* task id*/, IDataSource[]> = {};
    docToSourceTabParams: Record<string /* document id*/, ISourceTabParams> = {};

    constructor() {
        makeAutoObservable(this);
    }
    resetSummaries(summaries: Record<string, ISummary>) {
        this.summaries = summaries;
    }

    addSummary(taskId: string, summary: ISummary) {
        this.summaries[taskId] = summary;
    }
    clearSummaries() {
        this.summaries = {};
    }
    getSummaryByTaskId(taskId: string): ISummary | undefined {
        return this.summaries[taskId];
    }

    async updateAnnotatedSummaryData(taskId: string, summaryId: string, editedSection: ISummarySection, editedSectionIndex: number) {
        const debug_mode = getDebugMode();
        try {
            const newAnnotatedSummary = { ...this.annotatedSummary };
            newAnnotatedSummary.sections[editedSectionIndex] = editedSection;
            const result = await axios.put(
                `${BACKEND_URL}/${SUMMARY_PATH}${summaryId}/update`,
                {
                    annotated_summary: this.annotatedSummary
                },
                {
                    params: debug_mode ? { debug_mode } : {},
                    ...DEFAULT_COFIG
                }
            );
            runInAction(() => {
                this.annotatedSummary.sections[editedSectionIndex] = editedSection;
                this.summaries[taskId].summaryData.annotated_summary = this.annotatedSummary;
                const highlights = this.getHighlightsFromAnnotatedSummary();
                highlightStore.updateHighlights(highlights, SummaryType.summary);
            });
            return result;
        } catch (error) {
            console.error('Error updating annotated summary', error);
            throw error;
        }
    }


    // deprecated
    async updateSummaryData(taskId: string, summaryId: string, editedSection: any, editedSectionIndex: number) {
        this.annotations.sections[editedSectionIndex] = editedSection;
        const debug_mode = getDebugMode();
        const result = await axios.put(
            `${BACKEND_URL}/${SUMMARY_PATH}${summaryId}/update`,
            {
                annotations: JSON.stringify(this.annotations),
            },
            {
                params: debug_mode ? { debug_mode } : {},
                ...DEFAULT_COFIG
            }
        );
        runInAction(() => {
            this.annotations.sections[editedSectionIndex] = editedSection;
            this.summaries[taskId].summaryData.annotations = JSON.stringify(this.annotations);
            const highlights = this.getHightlightsFromAnnotation();
            highlightStore.updateHighlights(highlights, SummaryType.summary);
        });
        return result;
    }

    async fetchSummaryData(taskId: string, summaryId: string) {
        const debug_mode = getDebugMode();
        const result = await axios.get(
            `${BACKEND_URL}/${SUMMARY_PATH}${summaryId}/`,
            {
                params: debug_mode ? { debug_mode } : {},
                ...DEFAULT_COFIG
            }
        );
        runInAction(() => {
            // const _summary = this.summaries[taskId];
            this.summaries[taskId] = {
                taskId: taskId,
                summaryId: summaryId,
                summaryData: result.data
            }
            if (result.data.annotated_summary) {
                this.updateAnnotatedSummary(result.data.annotated_summary);
                const highlights = this.getHighlightsFromAnnotatedSummary();
                highlightStore.updateHighlights(highlights, SummaryType.summary);
            } else if (result.data.annotations) { // deprecated
                this.updateAnnotations(result.data.annotations);
                const highlights = this.getHightlightsFromAnnotation();
                highlightStore.updateHighlights(highlights, SummaryType.summary);
            }
        });
        return result;
    }

    // Get the search parameters that need to be set in the window URL, which will then change the UI.
    getSourceTabParamsFromDocumentId(documentId: string): ISourceTabParams | undefined {
        return this.docToSourceTabParams[documentId];
    }

    updateDataSources(taskId: string, dataSources: IDataSource[]) {
        console.log(`updateDataSources for summary ${taskId}`);
        this.dataSources[taskId] = dataSources;
        dataSources.forEach((dataSource) => {
            dataSource.filesAndMedia.forEach((sourceDocument) => {
                this.docToSourceTabParams[sourceDocument.id] = {
                    sourceName: dataSource.sourceName,
                    sourcePanel: FILES_SUBTAB_TITLE,
                }
            });
            dataSource.overview?.sourceDoc.forEach((sourceDocument) => {
                this.docToSourceTabParams[sourceDocument.id] = {
                    sourceName: dataSource.sourceName,
                    sourcePanel: OVERVIEW_SUBTAB_TITLE,
                }
            });
        })
    }

    private updateAnnotations(annotations: any) {
        this.annotations = JSON.parse(annotations);
    }
    private updateAnnotatedSummary(annotatedSummary: any) {
        this.annotatedSummary = annotatedSummary;
    }

    // deprecated
    private getHightlightsFromAnnotation(): IAnnotationHighlight[] {
        const allHighlights: any[] = [];
        if (this.annotations) {
            console.log('this.annotations', JSON.stringify(this.annotations));
            this.annotations.sections.forEach((section: ISection) => {
                section.lines.forEach((line: ILine) => {
                    if (line.annotations) {
                        line.annotations.forEach((highlight) => {
                            allHighlights.push(highlight);
                        })
                    }
                })
            })
        }
        return allHighlights;
    }

    private getHighlightsFromAnnotatedSummary(): IAnnotationHighlight[] {
        const allHighlights: IAnnotationHighlight[] = [];
        if (this.annotatedSummary) {
            this.annotatedSummary.sections.forEach((section: ISummarySection) => {
                section.quotes.forEach((quote: IQuote) => {
                    allHighlights.push({
                        id: quote.reference_number,
                        position: quote.position,
                        source: quote.source
                    })
                })
            })
        }
        return allHighlights;
    }
}