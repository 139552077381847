import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getCellComponent } from './CellComponents';
import TemplateEditModal from './TemplateEditModal';
import TemplateDeleteModal from './TemplateDeleteModal';
import { Box } from '@mui/material';
import TemplatePublishModal from './TemplatePublishModal';

export interface IDataTableProps {
    rows: any;
    columnConfig: any[];
    selectedRowIds: string[];
    setSelectedRowIds: (selectedRowIds: string[]) => void;
    tableRevision: number;
    setTableRevision: (tableRevision: number) => void;
}

const TemplateTable: React.FC<IDataTableProps> = (props) => {
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openPublishModal, setOpenPublishModal] = React.useState(false);
    const [isPublished, setIsPublished] = React.useState(undefined);

    React.useEffect(() => {
        console.log(`TemplateTable mounted`);
    }, []);
    // define a callback function that opens the modal to edit the isPublish
    // const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {



    const columns: GridColDef[] = props.columnConfig.map((config: any) => {
        let newConfig = {
            ...config,
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        };


        if (config['renderCell']) {
            newConfig = {
                ...newConfig,
                renderCell: (params: any) => getCellComponent(config['renderCell'])({
                    ...params,
                    setOpenEdit: setOpenEdit,
                    setOpenDelete: setOpenDelete,
                    setOpenPublishModal: setOpenPublishModal,
                    setIsPublished: setIsPublished,
                }),
            };
        }
        return newConfig;
    });
    return (
        <Box sx={{
            overflow: 'hidden',
        }}>
            <DataGrid
                rows={props.rows}
                sx={{
                    overflowX: 'hidden',
                    '& .Mui-selected': {
                        backgroundColor: '#EDEDED !important',
                        // outline removed for entire selected row
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'inherit',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none', // outline retained for individual focused cell
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none', // outline retained for individual focused cell within
                    },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-checkboxInput': {
                        color: 'black', // change checkbox color to black
                    },
                    '& .MuiCheckbox-root.Mui-checked': {
                        color: 'black', // ensure that the checked state is also black
                    },
                    '& .MuiDataGrid-checkboxInput:hover': {
                        backgroundColor: '#EBEBEB', // change hover background color of the checkbox
                    },
                    '& .MuiCheckbox-root:hover': {
                        backgroundColor: '#EBEBEB', // change hover background color for the checkbox container
                    },
                    '& .MuiDataGrid-checkboxInput.Mui-checked:hover': {
                        backgroundColor: '#EBEBEB', // change hover background color for checked state
                    },
                }}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onRowSelectionModelChange={(selectionModel) => {
                    console.log(`selectionModel: ${JSON.stringify(selectionModel)}`);
                    props.setSelectedRowIds(selectionModel as string[]);
                }}
            />
            <TemplateEditModal templateId={props.selectedRowIds[0]!} open={openEdit} setOpen={setOpenEdit} tableRevision={props.tableRevision} setTableRevision={props.setTableRevision} />
            <TemplateDeleteModal templateId={props.selectedRowIds[0]} open={openDelete} setOpen={setOpenDelete} tableRevision={props.tableRevision} setTableRevision={props.setTableRevision} />
            <TemplatePublishModal templateId={props.selectedRowIds[0]} open={openPublishModal} setOpen={setOpenPublishModal} tableRevision={props.tableRevision} setTableRevision={props.setTableRevision} isPublished={isPublished} />
        </Box >
    );
}
export default TemplateTable;
