import * as React from 'react';
import Button from '@mui/joy/Button';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Modal, ModalClose } from '@mui/joy';
import { newSection } from '../../stores/templates.store';
import { templateStore } from '../../stores/store';
import { observer } from 'mobx-react';
import DefaultTemplate from '../../data-schemas/BaseTemplate.json';
import TemplateEditCard from './TemplateEditCard';

// const useStyles = makeStyles((theme) => ({
//     modal: {
//         position: 'absolute',
//         top: '10%',
//         left: '10%',
//         overflow: 'scroll',
//         height: '100%',
//         display: 'block'
//     },
//     formLabel: {
//         width: '100px'
//     },
//     input: {
//         width: '500px'
//     },
//     textarea: {
//         width: '500px',
//         borderRadius: '12px 12px 0 12px',

//     },
//     title: {
//         fontSize: '1.5rem',
//         width: '500px',
//     }
// }));

const START_TEMPLATE = ['start_default', 'start_new'];


interface ICreateNewTemplateCardProps {
    setCardIndex: (index: number) => void;
    setUseDefault: (useDefault: boolean) => void;
}
const CreateNewTemplateCard: React.FC<ICreateNewTemplateCardProps> = (props) => {
    const [alignment, setAlignment] = React.useState<string>(START_TEMPLATE[0]);
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
        if (newAlignment === START_TEMPLATE[0]) {
            props.setUseDefault(true);
        } else {
            props.setUseDefault(false);
        }
    };

    return (
        <Stack spacing={2} direction="column" >
            <Typography id="basic-modal-dialog-title" level="h4">
                Create new template
            </Typography>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{
                    // Targeting all ToggleButton children
                    fontWeight: 'bold',
                    '& .MuiToggleButton-root': {
                        // Style for hover state
                        '&:hover': {
                            backgroundColor: '#EBEBEB',
                            fontWeight: 'bold'
                        },
                    },
                    // Targeting the selected ToggleButton
                    '& .Mui-selected, & .Mui-selected:hover': {
                        backgroundColor: '#CBCBCB',
                        fontWeight: 'bold',
                        // If you want to keep the hover effect for selected button as well
                        '&:hover': {
                            backgroundColor: '#BEBEBE', // Slightly different color or same as selected
                            fontWeight: 'bold'
                        },
                    },
                }}
            >
                <ToggleButton value={START_TEMPLATE[0]}>Start with a default template</ToggleButton>
                <ToggleButton value={START_TEMPLATE[1]}>Start from scratch</ToggleButton>
            </ToggleButtonGroup>
            <Typography id="basic-modal-dialog-title" component="h2">
                {alignment === START_TEMPLATE[0] ? 'Start with a base template and build on top of it' : 'Start from an empty template'}
            </Typography>
            <Stack spacing={2} direction="row">
                <Button onClick={() => props.setCardIndex(1)} style={{ backgroundColor: '#000000', fontWeight: 'bold' }} >Next</Button>
            </Stack>
        </Stack >
    );
};

interface ITemplateCreateModalProps {
    tableRevision: number;
    setTableRevision: (revision: number) => void;
}
const TemplateCreateModal: React.FC<ITemplateCreateModalProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [cardIndex, setCardIndex] = React.useState<number>(0);
    const [useDefault, setUseDefault] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (!open) {
            setCardIndex(0);
            setUseDefault(true);
            templateStore.setTemplateDraft(useDefault ? DefaultTemplate : [newSection]);
        }
    }, [open, useDefault]);

    return (
        <React.Fragment>
            <Button
                variant="solid"
                style={{ backgroundColor: '#000000', fontWeight: 'bold' }}
                startDecorator={<Add />}
                onClick={() => {
                    setOpen(true);
                }}
            >
                New Template
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog layout={'center'}
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ minWidth: 1000 }}
                >
                    <ModalClose />
                    {cardIndex === 0 && <CreateNewTemplateCard setCardIndex={setCardIndex} setUseDefault={setUseDefault} />}
                    {cardIndex === 1 && <TemplateEditCard setOpen={setOpen} tableRevision={props.tableRevision} setTableRevision={props.setTableRevision} />}
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
};

export default observer(TemplateCreateModal);
