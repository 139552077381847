import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { highlightStore, notificationStore, summaryStore, taskStore } from "../../stores/store";
import { BACKEND_URL, GET_DOCUMENT_PATH } from "../../constants/paths";
import axios from "axios";
import { DEFAULT_COFIG } from "../../utils/configs";
import { getFileNameFromMetadata, getSourcePath } from "../../utils/document-utils";
import DocumentView from "../DocumentViewPage/DocumentView";
import { CircularProgress, Link, Stack, Typography } from "@mui/joy";
import { observer } from "mobx-react";
import { SummaryType } from "../SummaryViewPage/constants";


const DocumentViewPage = () => {
    const searchParmas = new URLSearchParams(useLocation().search);
    const documentId = searchParmas.get('id');
    const taskId = searchParmas.get('task_id');
    const summaryId = searchParmas.get('summary_id');
    const [loading, setLoading] = useState<boolean>(false);
    const [sourceFilePath, setSourceFilePath] = useState<string>();
    const [fileName, setFileName] = useState<string>('');
    const taskUrl = `/summary/${taskId}`;
    useEffect(() => {
        console.log(`DocumentViewPage: taskId: ${taskId}, summaryId: ${summaryId}, documentId: ${documentId}`);
        if (!taskId || !summaryId || !documentId) {
            notificationStore.createNotification('error', `Invalid url`);
            return;
        }

        (async () => {
            setLoading(true);
            try {
                await taskStore.fetchTaskDetails(taskId);
                await summaryStore.fetchSummaryData(taskId, summaryId);
                const res = await axios.get(`${BACKEND_URL}/${GET_DOCUMENT_PATH}${documentId}`, DEFAULT_COFIG);
                const sourceFilePath = getSourcePath(res.data);
                setSourceFilePath(sourceFilePath ?? '');
                const _fileName = await getFileNameFromMetadata(res.data);
                setFileName(_fileName ?? '');

            } catch (err) {
                console.log(`FormHighlightViewer err: ${JSON.stringify(err)}`);
                notificationStore.createNotification('error', `Failed to download file or file does not exist`);
            }
            setLoading(false);
        })();
    }, []);


    return (
        <div>
            {
                loading ? <CircularProgress /> :
                    (sourceFilePath && taskId ? <>
                        <Stack direction="column" spacing={2} sx={{ marginTop: '1rem' }}>
                            <Typography level="h4">View summary here: <Link href={taskUrl}>{taskId}</Link></Typography>
                            <Typography level="h4">File name: {fileName}</Typography>
                            <DocumentView sourceFilePath={sourceFilePath} taskId={taskId} highlights={highlightStore.getPdfHighlights(SummaryType.summary)} />
                        </Stack>
                    </> : <Typography level="h3" margin='2rem'>File cannot be found. </Typography>
                    )
            }
        </div>
    );
}

export default observer(DocumentViewPage);
