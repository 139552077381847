import { makeAutoObservable, runInAction } from 'mobx';
import { Store } from './store';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { BACKEND_URL, CREATE_SUMMARY_TASK_PATH, TASKS_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';
import { getDebugMode } from '../utils/get-stage';
import { IEhrPatient, INetworkPatient } from '../types/data-source';

export interface ICreateTask {
    status: 'Pending' | 'Started' | 'Error';
    patientIdentifier: string;
    errorMessage: string;
    taskId?: string;
}

export interface ITask {
    taskId: string;
    status: string;
    summary_name: string;
    template: string; // template id
    template_content: any;
    ehr_patient: IEhrPatient;
    network_patient?: INetworkPatient; // deprecated
    metriport_patient?: INetworkPatient;
    input_files: any[];
    uploaded_resources: any;
    summary: string; // summary id, not actual data
    patient_demographics: any;
    patient_identifier: string;
    should_query_network: boolean;
    should_query_ehr: boolean;
    should_compute_risk_factors: boolean;
    risk_factors: string; // risk_factor id, not actual data
    timeline: string; // timeline id, not actual data
}

export interface ITaskMetaData {
    taskId: string;
    summaryName: string;
    patientIdentifier: string;
    patientDemographics: any;
}

export const MAX_TASKS_IN_BATCH = 5;
export default class TaskStore implements Store {
    newTasks: Record<string /* task id*/, ICreateTask> = {};
    tasks: Record<string /* task id*/, ITask> = {};

    constructor() {
        makeAutoObservable(this);
    }
    getNumTasks() {
        return Object.keys(this.newTasks).length;
    }

    fetchTaskDetails = async (taskId: string) => {
        console.log(`fetching task ${taskId}`);
        const result = await axios.get(`${BACKEND_URL}/${TASKS_PATH}${taskId}/`, DEFAULT_COFIG);
        runInAction(() => {
            this.tasks[taskId] = result.data;
        });
        return this.tasks[taskId];
    }
    updateAndRefreshTask = async (taskId: string, data: any) => {
        await axios.put(`${BACKEND_URL}/${TASKS_PATH}${taskId}/update/`, data, DEFAULT_COFIG);
        await this.fetchTaskDetails(taskId);
        return this.tasks[taskId];
    }

    addToTasks = (taskId: string, task: ITask) => {
        this.tasks[taskId] = task;
    }

    createBatchTasks = async (data: any[]) => {
        if (data.length > MAX_TASKS_IN_BATCH) {
            throw new Error(`Cannot create more than ${MAX_TASKS_IN_BATCH} tasks in a batch`);
        }

        const updatedData = data.map((item) => {
            return {
                ...item,
                request_id: item.request_id ?? uuidv4(),
            }
        })
        for (let i = 0; i < updatedData.length; i++) {
            const item = updatedData[i];
            this.addNewTask(item.request_id, item.patient_identifier);
        }
        return axios.post(`${BACKEND_URL}/${CREATE_SUMMARY_TASK_PATH}`, updatedData, DEFAULT_COFIG);
    }

    updateTaskToCreateStatus(props: { requestId: string, statusCode: number, taskId?: string, errorMessage?: string }) {
        if (props.statusCode === 202 && props.taskId) {
            this.newTasks[props.requestId] = {
                ...this.newTasks[props.requestId],
                status: 'Started',
                errorMessage: '',
                taskId: props.taskId,
            }
        } else {
            this.newTasks[props.requestId] = {
                ...this.newTasks[props.requestId],
                status: 'Error',
                errorMessage: props.errorMessage ?? '',
                taskId: props.taskId,
            }
        }
    }
    getTaskByRequestId(requestId: string) {
        return this.newTasks[requestId];
    }

    clearTasks() {
        this.newTasks = {};
    }

    getTaskMetadata(taskId: string): ITaskMetaData {
        const _task = this.tasks[taskId];
        return {
            taskId: _task.taskId,
            summaryName: _task.summary_name,
            patientIdentifier: _task.patient_identifier,
            patientDemographics: _task.patient_demographics,
        };
    }

    private addNewTask(requestId: string, patientIdentifier: string) {
        this.newTasks[requestId] = {
            patientIdentifier,
            status: 'Pending',
            errorMessage: '',
        }
    }
}