import { IUser } from "../types/user"
import { isNullOrEmpty } from "../utils/object-utils"

export const PATIENT_DEMOGRAPHICS_TAB_ALLOWLIST = ['integration-test@hona.ai']
export const PATIENT_ID_TAB_ALLOWLIST = ['integration-test@hona.ai']

export const isHonaDomain = (email: string) => {
    console.log('enableDebugMode email: ', email)
    return email && email.endsWith('@hona.ai')
}

// export const AUTH_PROVIDER_ALLOWLIST = ['flatiron', 'NYCBS']
export const isEHRUser = (user: IUser) => {
    return !isNullOrEmpty(user.ehr_name) && !isNullOrEmpty(user.client_name) && !user.client_name?.startsWith('AUTH0') && user.ehr_name !== 'google'

    // const auth_provider = username.split(':')[0]
    // return AUTH_PROVIDER_ALLOWLIST.includes(auth_provider)
}
