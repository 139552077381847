export const EHR_SOURCES_NAME = 'From EHR';
export const NETWORK_SOURCES_NAME = 'From Care Network';
export const UPLOADED_SOURCES_NAME = 'Directly Uploaded Files';

export const OVERVIEW_SUBTAB_TITLE = 'Overview';
export const FILES_SUBTAB_TITLE = 'Files & Media';
export const TAB_TITLES = [OVERVIEW_SUBTAB_TITLE, FILES_SUBTAB_TITLE];

// The following are param keys in URL
export const SOURCE_NAME_PARAM = 'sourceName';
export const SOURCE_PANEL_PARAM = 'sourcePanel';
export const SUMMARY_TYPE_PARAM = 'summaryType';

export const SUMMARY_TAB_TITLE = 'Summary';
export const RISK_FACTORS_TAB_TITLE = 'Risk Factors';
export const TIMELINE_TAB_TITLE = 'Timeline';

// possible values for param `summaryType` in URL 
export enum SummaryType {
    summary = "summary",
    risk_factor = "riskfactor",
    timeline = "timeline",
};