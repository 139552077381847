import { makeAutoObservable, runInAction } from 'mobx';
import { Store } from './store';
import axios from 'axios';
import { BACKEND_URL, GET_CLIENT_CONFIG_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';

interface searchFilter {
    //
    name: string;
    // TODO(shuying): currently not used. Implement filtering alter.
    userSearchFilters: any;
    defaultSearchFilters?: any;

}

export default class ClientConfigStore implements Store {
    // Search filters config for each resource type when data source is EHR.
    searchFiltersConfig: Record<string /* resource type*/, searchFilter> = {};

    // rjsf schemas to display EHR overview
    schemas: any[] = [];
    uiSchemas: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }
    getSearchFiltersConfig() {
        return this.searchFiltersConfig;
    }

    async fetchSearchFiltersConfig() {
        if (Object.keys(this.searchFiltersConfig).length > 0) {
            return this.searchFiltersConfig;
        }
        const result = await axios.get(`${BACKEND_URL}/${GET_CLIENT_CONFIG_PATH}?config_type=search_filters`, DEFAULT_COFIG);
        runInAction(() => {
            const _searchFilters: Record<string, searchFilter> = {}; // Add index signature
            Object.entries(result.data.search_filters).forEach(([resourceType, searchFilter]) => {
                _searchFilters[resourceType] = {
                    name: (searchFilter as any).name,
                    userSearchFilters: {},
                    defaultSearchFilters: (searchFilter as any).default_search_filters
                }
            }
            );
            this.searchFiltersConfig = _searchFilters;
        });
        return this.searchFiltersConfig;
    }
    async getEhrOverviewSchemas() {
        if (this.schemas.length > 0) {
            return this.schemas;
        }
        const result = await axios.get(`${BACKEND_URL}/${GET_CLIENT_CONFIG_PATH}?config_type=schemas`, DEFAULT_COFIG);
        this.schemas = result.data.schemas;
        return Promise.resolve(this.schemas);
    }
    async getEhrOverviewUiSchemas() {
        if (this.uiSchemas.length > 0) {
            return this.uiSchemas;
        }
        const result = await axios.get(`${BACKEND_URL}/${GET_CLIENT_CONFIG_PATH}?config_type=uischemas`, DEFAULT_COFIG);
        this.uiSchemas = result.data.uischemas;
        return Promise.resolve(this.uiSchemas);
    }
}