import { makeAutoObservable, runInAction } from 'mobx';
import { Store } from './store';
import { Patient } from 'fhir/r4';
import axios from 'axios';
import { BACKEND_URL, GET_PATIENT_PATH } from '../constants/paths';
import { DEFAULT_COFIG } from '../utils/configs';


export default class PatientStore implements Store {
    patients: Record<string /* patient id*/, Patient> = {};

    constructor() {
        makeAutoObservable(this);
    }
    async fetchPatient(patientId: string) {
        console.log('fetchPatient called');
        const params = {
            identifier: patientId,
        }
        const result = await axios.get(`${BACKEND_URL}/${GET_PATIENT_PATH}`,
            {
                params,
                ...DEFAULT_COFIG
            });

        runInAction(() => {
            const patient = result.data.entry[0].resource as Patient;
            this.patients[patientId] = patient;
        });

        return result;
    }
    getPatient(patientId: string) {
        return this.patients[patientId];
    }

    async getOrFetchPatient(patientId: string) {
        if (this.patients[patientId]) {
            return this.patients[patientId];
        } else {
            await this.fetchPatient(patientId);
            return this.patients[patientId];
        }
    }
}