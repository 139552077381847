import axios from 'axios';
import { BACKEND_URL, LOGIN_VERIFY_PATH, LOGOUT_PATH } from '../constants/paths';

export const login = async (email: string, password: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const response = await axios.post(`${BACKEND_URL}/auth/jwt/create`, body, config);
    console.log(`shuying debug login response: ${JSON.stringify(response.data)}`);
    localStorage.setItem("access", response.data.access);
    localStorage.setItem("refresh", response.data.refresh);
    return response.data;
  } catch (error) {
    console.log(`shuying debug login error: ${JSON.stringify(error)}`);
    throw error;
  }
};

export const verifyAuthenticated = async () => {
  try {
    const config = {
      withCredentials: true,
    }
    console.log(`shuying debug verifyAuthenticated called`);
    const response = await axios.get(`${BACKEND_URL}/${LOGIN_VERIFY_PATH}`, config);
    console.log(`shuying debug verifyAuthenticated response: ${JSON.stringify(response.data)}`);
    if (response.data?.user) {
      localStorage.setItem("user", JSON.stringify(response.data.user.id));
    }
    return response.data;
  } catch (error) {
    console.log(`shuying debug verifyAuthenticated error: ${JSON.stringify(error)}`);
    throw error;
  }
}

export const logout = async () => {
  const config = {
    withCredentials: true,
  };
  try {
    const response = await axios.post(`${BACKEND_URL}/${LOGOUT_PATH}`, {}, config);
    console.log(`shuying debug logout response: ${JSON.stringify(response.data)}`);
    localStorage.removeItem("user");
    return response.data;
  } catch (error) {
    console.log(`shuying debug logout error: ${JSON.stringify(error)}`);
    throw error;
  }
};

export const activateAccount = async (uid: string, token: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ uid, token });
  try {
    const response = await axios.post(
      `${BACKEND_URL}/auth/users/activation/`,
      body,
      config);
    console.log(`shuying debug activateAccount response: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    console.log(`shuying debug activateAccount error: ${JSON.stringify(error)}`);
    throw error;
  }
};

export const signup = async (email: string, password: string, re_password: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password, re_password });
  try {
    const response = await axios.post(
      `${BACKEND_URL}/auth/users/`,
      body,
      config);
    console.log(`shuying debug signup response: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    console.log(`shuying debug signup error: ${JSON.stringify(error)}`);
    throw error;
  }
}