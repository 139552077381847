import * as React from 'react';
import Button from '@mui/joy/Button';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Modal, ModalClose } from '@mui/joy';
import { notificationStore, templateStore } from '../../stores/store';

interface TemplateEditModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    templateId: string;
    tableRevision?: number;
    setTableRevision?: (tableRevision: number) => void;
}
const TemplateDeleteModal = (props: TemplateEditModalProps) => {
    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <ModalDialog layout={'center'}
                aria-labelledby="basic-modal-dialog-title"
                aria-describedby="basic-modal-dialog-description"
                sx={{ minWidth: 1000 }}
            >
                <ModalClose />
                <Stack direction="column" spacing={2}>
                    <Typography level="title-lg">Delete this template?</Typography>
                    <Typography level="title-md"
                        sx={{ color: 'text.secondary' }}
                    >This action cannot be undone.</Typography>
                    <Stack direction="row" spacing={2}>
                        <Button variant="solid" style={{ backgroundColor: '#000000', fontWeight: 'bold' }} onClick={() => props.setOpen(false)}>Cancel</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#000000', fontWeight: 'bold' }} onClick={() => {
                            templateStore.deleteTemplate(props.templateId).then(() => {
                                props.setOpen(false);
                                props.setTableRevision && props.setTableRevision(props.tableRevision ? props.tableRevision + 1 : 1);
                            }).catch((error) => {
                                props.setOpen(false);
                                notificationStore.createNotification('error', 'Failed to delete template');
                                props.setTableRevision && props.setTableRevision(props.tableRevision ? props.tableRevision + 1 : 1);
                            });
                        }}>Yes. Delete this template</Button>
                    </Stack>
                </Stack>
            </ModalDialog>
        </Modal>

    )
};

export default TemplateDeleteModal;