import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import { makeStyles } from '@material-ui/core';
import { IconButton } from '@mui/material';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { Card, Textarea } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';
import { notificationStore, templateStore } from '../../stores/store';
import { observer } from 'mobx-react';
import { SectionType, TemplateContentType } from '../../types/template';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserContext from '../Context/UserContext';

const useStyles = makeStyles((theme) => ({
    formLabel: {
        width: '100px'
    },
    input: {
        width: '500px',
    },
    textarea: {
        width: '500px',
        borderRadius: '12px 12px 0 12px',
    },
    title: {
        fontSize: '1.5rem',
        width: '550px',

    },
    visibilitySelect: {
        width: '550px',

    },
    hover: {
        "&:hover": {
            backgroundColor: '#EDEDED'
        }
    }
}));



interface ISectionCardProps {
    cardIndex: number;
};

const SectionCard: React.FC<ISectionCardProps> = observer((props) => {
    const classes = useStyles();
    const handleRemoveSection = () => {
        templateStore.removeSectionToDraft(props.cardIndex);
    };
    const updateTemplateSection = (targetKey: SectionType, targetValue: string) => {
        templateStore.updateSectionToDraft(props.cardIndex, targetKey, targetValue)
    }
    return (
        <Card variant="outlined"
            className={classes.hover}
            sx={{
                width: 700,
            }}>
            <Stack direction="row" sx={{ width: 700 }}>
                <Stack spacing={2}>
                    <FormControl>
                        <Stack direction="row" spacing={2}>
                            <FormLabel className={classes.formLabel}>Name</FormLabel>
                            <Input
                                name={`name${props.cardIndex}`}
                                className={classes.input}
                                autoFocus
                                required
                                value={templateStore.templateDraft[props.cardIndex]['name']}
                                onChange={(e) => updateTemplateSection('name', e.target.value)} />
                        </Stack>
                    </FormControl>
                    <FormControl>
                        <Stack direction="row" spacing={2}>
                            <FormLabel className={classes.formLabel}>Description</FormLabel>
                            <Textarea
                                className={classes.textarea}
                                color="neutral"
                                size="md"
                                name={`description${props.cardIndex}`}
                                minRows={4}
                                placeholder=''
                                value={templateStore.templateDraft[props.cardIndex]['description']}
                                onChange={(e) => { updateTemplateSection('description', e.target.value) }} />
                        </Stack>
                    </FormControl>
                </Stack>
                <IconButton onClick={handleRemoveSection} aria-label="delete section" sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', fontWeight: 'bold' }}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        </Card >
    );
});

interface ITemplateEditProps {
    setOpen: (open: boolean) => void;
    templateId?: string;
    tableRevision: number;
    setTableRevision: (revision: number) => void;
};
const TemplateEditCard: React.FC<ITemplateEditProps> = (props) => {
    const classes = useStyles();
    const [title, setTitle] = React.useState('');
    const { user } = React.useContext(UserContext);

    const handleAddSection = () => {
        templateStore.addNewSectionToDraft();
    }
    const onSubmit = (event: any) => {
        event.preventDefault();
        const trimmedTemplate = templateStore.templateDraft.filter((section) => section.name !== '');

        const payload = {
            name: title,
            template: trimmedTemplate,
            client_name: user.client_name,
        };
        // TODO(shuying): check if there is any change. If not change then directly close the dialog. 

        if (props.templateId) {
            templateStore.updateTemplate(props.templateId, payload).then(() => {
                notificationStore.createNotification('success', `Template change saved successfully`);
            }).catch((error) => {
                console.log(error);
                notificationStore.createNotification('error', `Failed to update template. Please try again or contact Hona team for support.`);
            });
        } else {
            templateStore.createTemplate(payload).then((result) => {
                console.log(result);
                notificationStore.createNotification('success', `Template ${result.data.name} created successfully`);
            }).catch((error) => {
                console.log(error);
                notificationStore.createNotification('error', `Failed to create template. Please try again or contact Hona team for support.`);
            });
        }
        props.setOpen(false);
        props.setTableRevision(props.tableRevision + 1);
    }

    // ResizeObserver loop limit exceeded: https://stackoverflow.com/questions/75774800/how-to-stop-resizeobserver-loop-limit-exceeded-error-from-appearing-in-react-a 
    React.useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    React.useEffect(() => {
        // templateStore.resetTemplateDraft();
        if (props.templateId) {
            const template = templateStore.getTemplateById(props.templateId);
            setTitle(template.name ?? '');
            // setVisibility(template.department ? 'department' : 'personal');
            templateStore.setTemplateDraft(template.template as TemplateContentType);
        }

    }, []);

    return (
        <div style={{ overflow: 'scroll' }}>
            <form
                onSubmit={onSubmit}
            >
                <Stack spacing={4} direction="column">
                    <Stack spacing={4} direction="row">
                        <Stack spacing={2} direction="column">
                            <FormControl>
                                <Stack direction="column">
                                    <FormLabel required>Title</FormLabel>
                                    <Input name="title" className={classes.title}
                                        autoFocus required
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Stack>
                            </FormControl>
                        </Stack>
                        <div>
                            <List>
                                {
                                    templateStore.templateDraft.map((data, index) => (
                                        <ListItem key={index}>
                                            <SectionCard cardIndex={index} />
                                        </ListItem>))}
                            </List>
                            <Stack spacing={2} direction="row">
                                <Button onClick={handleAddSection} style={{ backgroundColor: '#000000', fontWeight: 'bold' }}>Add Section</Button>
                            </Stack>
                        </div>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <Button
                            type="submit"
                            style={{ backgroundColor: '#000000', fontWeight: 'bold' }}
                        >
                            Save
                        </Button>
                        <Button
                            color="neutral"
                            onClick={() => { props.setOpen(false) }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </div >
    );
}

export default observer(TemplateEditCard);