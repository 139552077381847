export const LANDING_PAGE_ROUTER_LINK = "/";
export const DASHBOARD_ROUTER_LINK = "/dashboard";
export const LOGIN_ROUTER_LINK = "/login";
export const FIRST_TIME_LOGIN_ROUTER_LINK = "/first-time-login";
export const SIGN_IN_ROUTER_LINK = "/signin";
export const LAB_ANALYSIS_ROUTER_LINK = "/lab-analysis";
export const PROFILE_ROUTER_LINK = "/profile";
export const NEW_SUMMARY_ROUTER_LINK = "/new-summary";
export const ALL_SUMMARIES_LINK = "/all-summaries";
export const SUMMARY_DETAILS_LINK = "/summary";
export const SETTINGS_LINK = "/settings";
export const RESET_PASSWORD_ROUTER_LINK = '/reset-password';
export const RESET_PASSWORD_CONFIRMATION_ROUTER_LINK = '/password/reset/confirm/:uid/:token';
export const ACTIVATE_ACCOUNT_ROUTER_LINK = '/auth/activate/:uid/:token';
export const VERIFY_ACCOUNT_ROUTER_LINK = "/verify-account";
export const TEMPLATES_ROUTE_LINK = "/templates";
export const DOCUMENT_VIEWER_ROUTE_LINK = "/document";
export const SCHEDULE_ROUTE_LINK = "/schedule";
export const PDF_HIGHLIGHTER_LINK = "/pdf-highlighter";
export const EMAIL_VERIFIED_ROUTE_LINK = "/email_verified";