export interface IOrganization {
    id: string;
    name: string;
}
export interface IDepartment {
    id: string;
    name: string;
}
export interface IUser {
    id: string;
    username: string;
    name: string;
    first_name: string;
    last_name: string;
    organization?: IOrganization;
    department?: IDepartment;
    email?: string;
    ehr_name?: string;
    client_name?: string;
}

export const DEFAULT_USER: IUser = {
    id: '',
    username: '',
    name: '',
    first_name: '',
    last_name: '',
}