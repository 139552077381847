import { observer } from "mobx-react";
import PdfHighlightViewer from "../Common/PdfHighlightViewer";
import { IHighlight } from "../../react-pdf-highlighter/types";

interface IDocumentViewProps {
    sourceFilePath: string;
    taskId: string;
    highlights: IHighlight[];
}

const DocumentView: React.FC<IDocumentViewProps> = (props) => {
    return (
        <div>
            <PdfHighlightViewer highlights={props.highlights} sourceFilePath={props.sourceFilePath} taskId={props.taskId} />
        </div>
    );
}

export default observer(DocumentView);