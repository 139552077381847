import { createContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { verifyAuthenticated } from '../../actions/auth';
import axios from 'axios';
import { BACKEND_URL, LOGIN_VERIFY_PATH } from '../../constants/paths';
import { getStage } from '../../utils/get-stage';
import { CircularProgress } from '@mui/joy';
import Box from '@mui/material/Box';

interface IAuthContext {
  isAuthenticated: boolean | null;
  checkAuthentication: () => void;
  updateIsAuthenticated: (isAuthenticated: boolean) => void;
  stage: string;
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: null,
  checkAuthentication: () => { },
  updateIsAuthenticated: (isAuthenticated: boolean) => { },
  stage: 'local'
});



/**
 * Wrap auth states mobx store in a context such that react components don't always have to observe the store. 
*/
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean | null>(null);
  // const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updateIsAuthenticated = useCallback((isAuthenticated: boolean) => {
    setAuthenticated(isAuthenticated);
  }, []);

  useEffect(() => {
    // Note: In StrictMode, React will double-invokes useEffect() and call API twice: https://github.com/facebook/react/issues/24502
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      setLoading(true);
      try {
        const config = {
          withCredentials: true,
          cancelToken: source.token
        }
        await axios.get(`${BACKEND_URL}/${LOGIN_VERIFY_PATH}`, config);
        updateIsAuthenticated(true);
        setLoading(false);
      } catch (error) {
        updateIsAuthenticated(false);
        setLoading(false);
      }
    };
    fetchData();
    // Cancel the request if component is unmounted
    return () => {
      console.log('Cancelling request login verify');
      source.cancel();
    };
  }, []); // Empty dependency array ensures this runs once on mount and not on updates

  const checkAuthentication = useCallback(async () => {
    console.log(`checkAuthentication is called!`);
    setLoading(true);
    try {
      await verifyAuthenticated();
      setAuthenticated(true);
      setLoading(false);
    } catch (e) {
      console.log(`checkAuthentication error: ${JSON.stringify(e)}`);
      setAuthenticated(false);
      setLoading(false);
    }
  }
    , []);

  return (
    loading ? (
      <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <img src="/img/hona_logo_with_text.png" alt="Logo" style={{ marginLeft: "20px", height: "50px", marginBottom: "20px", marginTop: "20px" }} />
        <CircularProgress color="neutral" />
      </Box>
    )
      : (
        <AuthContext.Provider value={{ isAuthenticated, checkAuthentication, updateIsAuthenticated, stage: getStage() }}>
          {children}
        </AuthContext.Provider>
      )
  );
};

export default AuthContext;


