import { useContext, useEffect, useState } from "react";
import { templateStore } from "../../stores/store";
import { observer } from "mobx-react";
import TemplateTable from "../TemplatesPage/DataTable";
import myTemplatesColumnConfigs from "../../data-schemas/MyTemplateTableColumnConfig.json";
import { CircularProgress } from '@mui/joy';
import Stack from "@mui/material/Stack";
import TemplateCreateModal from "../TemplatesPage/TemplateCreateModal";
import { Button, Typography } from "@mui/joy";
import { InfoOutlined } from "@mui/icons-material";
import UserContext from "../Context/UserContext";
import RefreshIcon from '@mui/icons-material/Refresh';
import { getDataForTableColumns } from "../../utils/table-utils";
import '../../fonts.css';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Box from '@mui/material/Box'


const theme = createTheme({
    typography: {
        fontFamily: [
            'HelveticaNowProTextRegular', // Replace with your font name
            'Arial',        // Fallback font
            'sans-serif'    // Generic font family
        ].join(','),
    },
});


interface IMyTemplatesProps {
    tableRevision: number;
    setTableRevision: (revision: number) => void;
}
const TemplatesTableComponent: React.FC<IMyTemplatesProps> = observer((props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

    const handleRefresh = () => {
        // setLoading(true);
        props.setTableRevision(props.tableRevision + 1);
    }

    useEffect(() => {
        setLoading(true);
        // Note: Without timeout, postgresql will sometimes read old value and user can't see the newly created template.
        setTimeout(() => {
            templateStore.getTemplateList().then((res) => {
                setData(getDataForTableColumns(templateStore.templateList, myTemplatesColumnConfigs));
                setLoading(false);
            }).catch((err) => {
                console.log(`error getting department templates: err: ${JSON.stringify(err)}`);
                setLoading(false);
            }
            )
        }, 500);
        // setLoading(false);
        // );
    }, [props.tableRevision]); // Use data: infinite loop
    return (
        <ThemeProvider theme={theme}>
            <Stack spacing={2} direction="column" sx={{ fontFamily: 'HelveticaNowProTextRegular' }}
            style={{overflow: 'hidden'}}>
                <Stack spacing={2} direction="row">
                    <Stack spacing={2} direction="column">
                        <Typography level="h3" sx={{ fontFamily: 'HelveticaNowProTextRegular' }} >My Templates</Typography>
                    </Stack>
                    <TemplateCreateModal tableRevision={props.tableRevision} setTableRevision={props.setTableRevision} />
                    {/* {user.department && <Button
                        variant="solid"
                        style={{ color: '#000000', fontFamily: 'HelveticaNowProTextRegular', fontWeight: 'bold' }}
                        startDecorator={<AccessTimeIcon />}
                        disabled={selectedRowIds.length === 0}
                        onClick={() => {
                        }}
                    >
                        Schedule
                    </Button>} */}
                    <Button startDecorator={<RefreshIcon />} onClick={handleRefresh} style={{ backgroundColor: '#000000', fontFamily: 'HelveticaNowProTextRegular', fontWeight: 'bold' }}>Refresh</Button>
                </Stack>
                <Typography startDecorator={<InfoOutlined />} mb={2} sx={{ fontFamily: 'HelveticaNowProTextRegular' }}>Select at least one row to share or schedule</Typography>
                <Box 
                sx={{overflow: 'hidden'}}
                >
                    {loading ? <CircularProgress color="neutral" /> :
                        <TemplateTable
                            columnConfig={myTemplatesColumnConfigs}
                            rows={data}
                            selectedRowIds={selectedRowIds}
                            setSelectedRowIds={setSelectedRowIds}
                            tableRevision={props.tableRevision}
                            setTableRevision={props.setTableRevision}
                        />}
                </Box>
            </Stack >
        </ThemeProvider>
    );
});

const TemplatesPage = () => {
    const [tableRevision, setTableRevision] = useState<number>(0); // Control rerender of the table whenever data changes.
    return (
        <div>
            <Stack spacing={10} direction="column">
                <TemplatesTableComponent tableRevision={tableRevision} setTableRevision={setTableRevision} />
            </Stack>
        </div >
    )
}

export default observer(TemplatesPage);