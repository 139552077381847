import FileStore from "./files.store";
import HtmlFragmentsStore from "./html-fragments.store";
import NotificationStore from "./notification.store";
import PatientStore from "./patient.store";
import SummaryStore from "./summary.store";
import TaskStore from "./task.store";
import TemplateStore from "./templates.store";
import UserStore from "./user.store";
import ClientConfigStore from "./client-configs.store";
import RiskFactorStore from "./risk-factors.store";
import TimelineStore from "./timeline.store";
import EncountersStore from "./encounters.store";
import HighlightStore from "./highlights.store";

export interface Store { }
export const highlightStore = new HighlightStore();
export const userStore = new UserStore();
export const templateStore = new TemplateStore();
export const notificationStore = new NotificationStore();
export const htmlFragmentsStore = new HtmlFragmentsStore();
export const summaryStore = new SummaryStore();
export const patientStore = new PatientStore();
export const taskStore = new TaskStore();
export const fileStore = new FileStore();
export const clientConfigStore = new ClientConfigStore();
export const riskFactorStore = new RiskFactorStore();
export const encountersStore = new EncountersStore();
export const timelineStore = new TimelineStore();