import { makeAutoObservable, runInAction } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import { BACKEND_URL, TEMPLATES_GALLERY_PATH, TEMPLATES_PATH } from '../constants/paths';
import { Store } from './store';
import { convertToDate } from '../utils/date-time';
import { ITemplate, SectionType, TemplateContentType } from '../types/template';


const DEFAULT_COFIG = {
    withCredentials: true,
}

export const newSection = { name: '', description: '' }
export interface ICustomTemplate extends ITemplate {
    id?: string; // At draft state, id is undefined
    created_by?: string;
    last_modified_by?: string;
    last_modified_datetime?: string;
    client_name?: string;
    is_published?: boolean;
}

export interface ITemplateResponse {
    id: string;
    name: string;
    template: TemplateContentType;
    created_by: string;
    is_published: boolean;
    last_modified_by: string;
    last_modified_datetime: string;
    client_name: string;
}

export default class TemplateStore implements Store {
    templateList: ICustomTemplate[] = [];
    templateDraft: TemplateContentType = [];
    templateMap: Record<string /* template id */, ICustomTemplate> = {}; // All templates in template table
    templateGallery: ITemplate[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async getTemplateList(): Promise<AxiosResponse<ITemplateResponse>> {
        const result = await axios.get(
            `${BACKEND_URL}/${TEMPLATES_PATH}`,
            DEFAULT_COFIG,
        );
        runInAction(() => {
            this.templateList = result.data.map((template: ITemplateResponse) => {
                return {
                    ...template,
                    createdBy: template.created_by,
                    isPublished: template.is_published,
                    lastModifiedBy: template.last_modified_by,
                    lastModifiedDateTime: convertToDate(template.last_modified_datetime).toLocaleDateString(),
                }
            });
            this.templateList.forEach((template: ICustomTemplate) => {
                this.templateMap[template.id!] = template;

            }
            );
        });

        return result;
    }

    async getTemplateGallery(): Promise<AxiosResponse<ITemplate[]>> {
        const result = await axios.get(`${BACKEND_URL}/${TEMPLATES_GALLERY_PATH}`, DEFAULT_COFIG);
        runInAction(() => {
            this.templateGallery = result.data
        });
        return result;
    }

    async createTemplate(payload: ICustomTemplate): Promise<AxiosResponse<any>> {
        try {
            const result = await axios.post(
                `${BACKEND_URL}/${TEMPLATES_PATH}create/`,
                payload,
                DEFAULT_COFIG,
            );
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async updateTemplate(templateId: string, payload: any): Promise<AxiosResponse<any>> {
        try {
            const result = await axios.put(
                `${BACKEND_URL}/${TEMPLATES_PATH}${templateId}/update/`,
                payload,
                DEFAULT_COFIG,
            );
            return result;

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async publishTemplate(templateId: string, is_published: boolean): Promise<AxiosResponse<any>> {
        try {
            const result = await axios.post(
                `${BACKEND_URL}/${TEMPLATES_PATH}${templateId}/publish/`,
                {},
                DEFAULT_COFIG,
            );
            return result;
        } catch (error) {
            console.log(`Error publishing template ${templateId}`);
            throw error;
        }
    }

    async deleteTemplate(templateId: string): Promise<AxiosResponse<any>> {
        try {
            const result = await axios.delete(
                `${BACKEND_URL}/${TEMPLATES_PATH}${templateId}/delete/`,
                DEFAULT_COFIG,
            );
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }


    addNewSectionToDraft(): void {
        this.templateDraft.push(newSection);
    }

    removeSectionToDraft(index: number): void {
        this.templateDraft.splice(index, 1);
    }

    updateSectionToDraft(index: number, targetKey: SectionType, targetValue: string): void {
        this.templateDraft[index][targetKey] = targetValue;

    }
    setTemplateDraft(template: TemplateContentType): void {
        this.templateDraft = template;
    }

    getTemplateById(id: string): ICustomTemplate {
        return this.templateMap[id];
    }
}
